import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { manageBranchesSchemaValidation } from '../../forms/manageBranchesSchemaValidations';
import { useToaster } from '../../contexts/ToasterContext';
import { getApiErrorMessage } from '../../services/errorApiService';
import { createBranchUser } from '../../api/commerceAffiliationApiService';

const useManageBranchesController = () => {
  const methods = useForm({
    resolver: yupResolver(manageBranchesSchemaValidation),
  });

  const { showToast } = useToaster();

  const [addUserLoading, setAddUserLoading] = useState(false);
  const [sellerList, setSellerList] = useState();

  const createUser = async (data) => {
    try {
      setAddUserLoading(true);
      const user = {
        identificationNumber: data.identifier,
        firstname: data.name,
        lastname: data.surname,
        email: data.email,
        phone: data.phone,
        profile: data.profile,
        branchId: data.branch.id,
      };
      const res = await createBranchUser(user);
      console.log(res);
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setAddUserLoading(false);
    }
  };

  const init = () => {
    // TODO: traer listado
    setSellerList([
      {
        // eslint-disable-next-line max-len
        id: 1, name: 'Mr. Test 1', identifier: '123456789', phone: '1122223333', email: 'test1@mail.com', profile: 'admin', branch: 'sucursal 1', status: 'PENDING',
      },
      {
        // eslint-disable-next-line max-len
        id: 2, name: 'Sr. Test 2', identifier: '987654321', phone: '1133334444', email: 'test2@mail.com', profile: 'vendedor', branch: 'sucursal 2', status: 'FINISHED',
      },
    ]);
  };

  useEffect(init, []);

  return {
    methods,
    sellerList,
    createUser,
    addUserLoading,
  };
};

export default useManageBranchesController;
