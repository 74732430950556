import { useState } from 'react';
import {
  Button,
  CardContent, FormControl, Radio, RadioGroup,
} from '@doit/pcnt-react-ui-library';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box, Grid, Tab, Typography,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import QRCode from 'react-qr-code';
import Card from '../../components/commons/Card';
import StepHeader from './components/StepHeader';
import useDocumentUploadController from '../../hooks/controllers/useDocumentUploadController';
import Loading from '../../components/commons/Loading';
import i18n from '../../common/i18n';
import { sendLinkSchemaValidation } from '../../forms/documentUploadSchemaValidations';
import TextInputControlled from '../../components/commons/TextInputControlled';

const DocumentUploadPage = () => {
  const {
    globalLoading,
    loanRequest,
    QR,
  } = useDocumentUploadController();

  const methods = useForm({
    resolver: yupResolver(sendLinkSchemaValidation),
  });

  const [currentTab, setCurrentTab] = useState('2');
  const [validateCustomerOption, setValidateCustomerOption] = useState('qr');

  if (globalLoading) {
    return <Loading />;
  }

  return (
    <>
      <Card>
        <CardContent>
          <StepHeader loanRequest={loanRequest} />
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Typography align="left" sx={{ fontSize: '20px', fontWeight: 600 }}>{i18n.DocumentUploadPage.title}</Typography>
          <Typography align="left" sx={{ fontSize: '14px', marginTop: '4px' }}>{i18n.DocumentUploadPage.description}</Typography>

          {/* TABS */}
          <TabContext value={currentTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '32px' }}>
              <TabList onChange={(e, value) => setCurrentTab(value)} aria-label="lab API tabs example">
                <Tab label={i18n.DocumentUploadPage.tabOptions[0]} value="1" />
                <Tab label={i18n.DocumentUploadPage.tabOptions[1]} value="2" />
              </TabList>
            </Box>
            {/* TAB ENVIAR LINK AL CLIENTE */}
            <TabPanel value="1" style={{ display: 'flex', paddingTop: '0px', paddingBottom: '0px' }}>
              <FormControl sx={{ width: '100%', marginTop: '32px' }}>
                <Typography align="left" sx={{ fontSize: '14px', marginBottom: '24px' }}>
                  {i18n.DocumentUploadPage.tab1.description}
                </Typography>
                <Grid container columnGap="40px" rowGap="20px" sx={{ backgroundColor: '#F5F5F5', padding: '12px 24px', width: 'fit-content' }}>
                  {i18n.DocumentUploadPage.tab1.steps.map((step, index) => (
                    <Grid item xs="auto" display="flex" alignItems="center" key={step.description}>
                      <img src={step.icon} alt={`paso_${index}`} />
                      <Typography align="left" sx={{ fontSize: '12px', marginLeft: '8px' }}>{step.description}</Typography>
                    </Grid>
                  ))}
                </Grid>
                <Box sx={{ marginTop: '24px' }}>
                  <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit((data) => console.log(data))} style={{ textAlign: 'left' }}>
                      <TextInputControlled
                        name="cellphone"
                        label={i18n.DocumentUploadPage.tab1.cellphoneInput}
                        helperText={i18n.DocumentUploadPage.tab1.cellphoneInputHint}
                        variant="outlined"
                        mandatory
                      />
                      <Box sx={{ marginTop: '24px', textAlign: 'right' }}>
                        <Button type="submit" variant="secondary">{i18n.DocumentUploadPage.tab1.ctaSendLink}</Button>
                      </Box>
                    </form>
                  </FormProvider>
                </Box>
              </FormControl>
            </TabPanel>
            {/* TAB VALIDAR CLIENTE DESDE AQUI */}
            <TabPanel value="2" style={{ display: 'flex', paddingTop: '0px', paddingBottom: '0px' }}>
              <FormControl sx={{ width: '100%', marginTop: '32px' }}>
                <RadioGroup
                  aria-labelledby="Regular-group"
                  name="radio-buttons-group"
                  onChange={(e) => setValidateCustomerOption(e.target.value)}
                >
                  <Radio size="small" value="qr" checked={validateCustomerOption === 'qr'} label={i18n.DocumentUploadPage.tab2.qrOption} />
                  {validateCustomerOption === 'qr' && (
                    <Box sx={{ display: 'flex', marginTop: '24px', marginBottom: '32px' }}>
                      <QRCode value={QR} />
                    </Box>
                  )}
                  <Radio size="small" value="link" checked={validateCustomerOption === 'link'} label={i18n.DocumentUploadPage.tab2.linkOption} />
                  { validateCustomerOption === 'link' && (
                    <Box sx={{ marginTop: '16px' }}>
                      <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit((data) => console.log(data))} style={{ textAlign: 'left' }}>
                          <Typography align="left" sx={{ fontSize: '14px', marginBottom: '24px' }}>
                            {i18n.DocumentUploadPage.tab2.linkOptionDescription}
                          </Typography>
                          <TextInputControlled
                            name="cellphone"
                            label={i18n.DocumentUploadPage.tab2.cellphoneInput}
                            helperText={i18n.DocumentUploadPage.tab2.cellphoneInputHint}
                            variant="outlined"
                            mandatory
                          />
                          <Box sx={{ marginTop: '24px', textAlign: 'right' }}>
                            <Button type="submit" variant="secondary">{i18n.DocumentUploadPage.tab2.ctaSendLink}</Button>
                          </Box>
                        </form>
                      </FormProvider>
                    </Box>
                  )}
                </RadioGroup>
              </FormControl>
            </TabPanel>
          </TabContext>
        </CardContent>
      </Card>
    </>
  );
};

export default DocumentUploadPage;
