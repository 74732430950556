import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  getDocuments, getLoanRequestWithDocumentData, npsAlreadyAnswered, getNPS,
} from '../../api/workflowApiService';
import {
  PREPAID_CARD, PLEDGE_DOCS, NPS_PRODUCT_LIST, NPS_ORGANIZATION,
} from '../../constants/commerceContants';
import { getApiErrorMessage } from '../../services/errorApiService';
import { useToaster } from '../../contexts/ToasterContext';
import { getEnv, CREDICUOTAS_COMMERCE_BACKEND_URL } from '../../api/env';
import useNavigation from '../useNavigation';
import { isProductToPayVehicle } from '../../utils/loanRequestUtils';

const useFinalController = () => {
  const { showToast } = useToaster();
  const { hash } = useParams();
  const urlBackend = getEnv(CREDICUOTAS_COMMERCE_BACKEND_URL);
  const { goHome } = useNavigation();

  const [globalLoading, setGlobalLoading] = useState(false);
  const [loanRequest, setLoanRequest] = useState();
  const [documents, setDocuments] = useState();
  const [isApproved, setIsApproved] = useState();
  const [isProductToPayVehicleType, setIsProductToPayVehicleType] = useState();
  const [isPledgeDocCompleted, setIsPledgeDocCompleted] = useState();
  const [showDanger, setShowDanger] = useState();

  const [showSurvey, setShowSurvey] = useState(false);
  const [surveyInfo, setSurveyInfo] = useState(false);
  const npsPopUpDelay = 1000;

  const openNps = () => {
    try {
      setTimeout(async () => {
        const surveyResponse = await getNPS(NPS_ORGANIZATION);
        if (surveyResponse && surveyResponse.data !== '') {
          setShowSurvey(true);
          setSurveyInfo(surveyResponse.data);
        }
      }, npsPopUpDelay);
    } catch (error) {
      // nothing to do
    }
  };

  const downloadVoucher = () => {
    window.open(`${urlBackend}/documents/download/${hash}/voucher`);
  };

  const downloadDocs = () => {
    window.open(`${urlBackend}/documents/download/${hash}/documentation`);
  };

  const onlyRequiredAndNotSettedFilterFunction = (item) => item.userInputOptions.required
    && (item.userInputOptions.defaultValue === undefined || item.userInputOptions.defaultValue === null
      || item.userInputOptions.defaultValue === '');

  const resolveIsPledgeDocCompleted = (docs) => {
    const pledgeDocsLoaded = docs.filter((doc) => PLEDGE_DOCS.includes(doc.name) && doc.userInputOptions.defaultValue);
    return pledgeDocsLoaded.length === PLEDGE_DOCS.length;
  };

  const init = async () => {
    try {
      setGlobalLoading(true);

      const { data: lr } = await getLoanRequestWithDocumentData(hash);
      setLoanRequest(lr);

      if (lr.productToPay && lr.productToPay.showcaseType
        && lr.productToPay.product.productAttributes.productAmount) {
        showToast('La solicitud tiene un link de pago generado.', 'info');
        // TODO: tema link de pago generado, va a seguir tal cual hoy en dia?
        // $location.path('/loanRequests');
      }

      const { data: docs } = await getDocuments(hash);
      setDocuments(docs);

      const showDangerAux = lr.documentsCompleted === false
        || (lr.customerDataCompleted === false && lr.documentsCompleted === false)
        || (lr.isPaperless && lr.hasSigned === false);
      setShowDanger(showDangerAux);

      const isProductToPayVehicleTypeAux = isProductToPayVehicle(lr);
      setIsProductToPayVehicleType(isProductToPayVehicleTypeAux);

      const isPledgeDocCompletedAux = resolveIsPledgeDocCompleted(docs);
      setIsPledgeDocCompleted(isPledgeDocCompletedAux);

      setIsApproved(lr.saleCompletionAutomatic === true || lr.disbursementMode === PREPAID_CARD
        || (isProductToPayVehicleTypeAux && isPledgeDocCompletedAux));

      if (NPS_PRODUCT_LIST.includes(lr.productId)) {
        const { data: anwered } = await npsAlreadyAnswered(NPS_ORGANIZATION);
        if (!anwered) {
          openNps();
        }
      }
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setGlobalLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return {
    globalLoading,
    loanRequest,
    documents,
    isApproved,
    isProductToPayVehicleType,
    isPledgeDocCompleted,
    showDanger,
    goHome,
    downloadVoucher,
    downloadDocs,
    onlyRequiredAndNotSettedFilterFunction,
    showSurvey,
    setShowSurvey,
    surveyInfo,
  };
};

export default useFinalController;
