/* eslint-disable no-template-curly-in-string */
/* eslint-disable max-len */
import { ROLE_SELLER, ROLE_MANAGER } from '../constants/rolesConstants';

const i18n = {
  Login: {
    title: 'Iniciar sesión',
    description: 'Ingresá tu usuario y contraseña',
    userInput: 'Usuario',
    passInput: 'Contraseña',
    rememberMe: 'Recordar mi usuario',
    forgotPass: 'Olvidé mi contraseña',
    loginCTA: 'Iniciar sesión',
    errors: {
      E001: 'Usuario y/o contraseña incorrectos',
      E002: 'Usuario no habilitado para operar',
      E003: 'Vendedor no habilitado para operar',
      E004: 'Usuario no habilitado para operar',
      E005: 'Comercio no habilitado para operar',
      E006: 'Casa Matriz no habilitada para operar',
      E007: 'Usuario no habilitado para esta aplicación',
      E008: 'Usuario y/o contraseña incorrectos',
      E009: 'Usuario no está dado de alta en el Comercio',
      E010: 'La configuración del Usuario o Comercio es incorrecta (No existe la relación Tipo Producto / Comercio)',
      E011: 'La configuración del Usuario o Comercio es incorrecta (No existe Tipo Producto)',
      E012: 'La configuración del Usuario o Comercio es incorrecta (No existe la relación Tipo Producto / Organismo)',
      E013: 'La configuración del Usuario o Comercio es incorrecta (No existe Organismo)',
      E014: 'La configuración del Usuario o Comercio es incorrecta (No existe Línea Comercializador)',
      E015: 'La configuración del Usuario o Comercio es incorrecta (No existe Línea)',
      E016: 'La configuración del Usuario o Comercio es incorrecta (No existe la relación Línea / Organismo)',
      E017: 'La configuración del Usuario o Comercio es incorrecta (No existe Comercio Categoría)',
      EMAIL_IS_NOT_VALID: 'No tenemos registrado un email válido parar tu usuario, por favor comunicate con tu asesor comercial o Mesa de Acuerdo.',
      EXPIRED_EXECUTE_USER_PASSWORD_RESET: 'El link de restablecer contraseña expiró. Volvé a iniciar el proceso.',
      CHANGE_PASSWORD_USER_INTERNET_CHANNEL_ERROR: 'No es posible modificar o recupear la contraseña de este usuario debido a su configuración de canal internet. Contactá a tu comercial de Credicuotas',
      GENERIC_ERROR: 'Ocurrió un error mientras se procesaba la solicitud. Por favor intente nuevamente más tarde o comuniquese con Credicuotas',
    },
    reviewInfoMessage: 'Revisa los datos ingresados y volvé a intentarlo',
    sessionExpired: 'Sesión expirada',
  },

  ForgotPassword: {
    title: [
      '¿Olvidaste tu contraseña?',
      'Revisá tu correo',
    ],
    description: [
      'Ingresá tu usuario y te enviaremos un mail con instrucciones para restrabecerla.',
      'Enviamos un mail a <b>${0}</b> para que puedas restablecer tu contraseña.',
      'Si no te llega el mail o esta no es tu casilla de correo, comunicate con tu asesor comercial o Mesa de Acuerdos.',
    ],
    userInput: 'Usuario',
    recoverCTA: 'Restablecer contraseña',
    cancelCTA: 'Cancelar',
    initSessionCTA: 'Iniciar sesión',
  },

  ExpiredPassword: {
    title: [
      'Actualizá tu contraseña',
      '¡Listo!',
    ],
    description: [
      'La nueva contraseña tiene que ser diferente a la actual, y debe contener 6 caracteres usando números y/o letras',
      'Restableciste tu contraseña con éxito',
    ],
    warningMessage: 'Tu contraseña expiró',
    newPassInput: 'Nueva contraseña',
    newPassRepeatInput: 'Repetir nueva contraseña',
    updateCTA: 'Actualizar contraseña',
    cancelCTA: 'Cancelar',
  },

  RestorePassword: {
    title: 'Contraseña restablecida',
    description: 'Te enviamos un email con la nueva contraseña para que puedas ingresar a la plataforma.',
  },

  schemaValidation: {
    user: {
      required: 'El usuario es obligatorio',
    },
    pass: {
      required: 'La contraseña es obligatoria',
    },
    newPass: {
      required: 'La nueva contraseña es obligatoria',
      minLength: 'La nueva contraseña debe ser de mínimo 6 caracteres',
      alphanumeric: 'Debe contener solo letras y/o números',
    },
    repeatNewPass: {
      required: 'La nueva contraseña es obligatoria',
      match: 'Las contraseñas no coinciden. Revisá que ambas estén escritas igual',
    },
    customerId: {
      required: 'El DNI es obligatorio',
      length: 'El DNI debe ser un número entre 6 y 9 dígitos',
    },
    product: {
      required: 'Seleccioná un producto',
    },
    subproduct: {
      required: 'Seleccioná un sub producto',
    },
    terms: {
      required: 'Seleccioná el plazo de pago',
    },
    customerTaxId: {
      required: 'Seleccioná una persona',
    },
    howMeetUs: {
      required: 'Seleccioná como nos conociste',
    },
    email: {
      format: 'El formato del email es inválido',
    },
    cellphone: {
      required: 'El celular es obligatorio',
      format: 'El formato del celular es inválido',
    },
  },

  SideMenu: {
    sections: {
      account: 'Cuenta',
    },
    options: {
      home: 'Inicio',
      newLoanRequest: 'Nueva solicitud',
      loanRequests: 'Solicitudes',
      renewers: 'Renovadores',
      liquidations: 'Liquidaciones',
      commission: 'Comisiones',
      sales: 'Panel de ventas',
      credicuotasClub: 'Club Credicuotas',
      manageBranches: 'Gestionar Sucursales',
      closeSession: 'Cerrar sesión',
    },
  },

  QuotationPage: {
    title: 'Nueva solicitud',
    productAndSubproductSection: 'Elegí el producto y sub producto',
    productLabel: 'Producto',
    productEmptyLabel: 'Seleccionar producto',
    subproductLabel: 'Sub Producto',
    subproductEmptyLabel: 'Seleccionar sub producto',
    termsLabel: 'Plazo de Pago',
    termsEmptyLabel: 'Seleccionar plazo de pago',
    customerDataSection: 'Completá los datos de la persona',
    customerIdLabel: 'DNI',
    customerIdHint: 'Sin espacios ni puntos',
    customerTaxIdLabel: 'Seleccioná una persona',
    customerIdNotFound: 'No se encontraron personas para ese DNI',
    genderLabel: 'Genero',
    howMeetUsLabel: '¿Cómo nos conoció?',
    pepLabel: '¿Es una Persona Políticamente Expuesta?',
    pepHelp: 'Se consideran PEPs a los Funcionarios Públicos que ocupan o que ocuparon altos cargos en puestos jerárquicos, como así también a sus familiares directos.',
    soLabel: '¿Es un Sujeto Obligado?',
    soHelp: 'Un Sujeto Obligado es una persona que tiene obligaciones ante la UIF por la actividad que realiza.',
    cta: 'Continuar',
    quotationUnavailable: 'La generación de nuevas solicitudes está deshabilitada para esta configuración de comercio',
    overlayLoadingMessage: 'Estamos haciendo un cálculo personalizado para encontrar la mejor oferta',
  },

  InstallmentPlanPage: {
    title: 'Monto total disponible: <span style="color: #E72064; font-weight: 700; font-size: 24px">${0}</span>',
    ctaReload: 'Actualizar oferta',
    requiredDocumentation: 'Documentación requerida',
    debitCard: 'Tarjeta de débito',
    selectInstallmentPlan: 'Seleccioná el plan de cuotas',
    vehicleSelection: {
      title: 'Seleccioná el modelo de moto',
      description: 'Ingresá la moto elegida por el cliente para calcular el máximo prestable.',
      brand: 'Marca',
      model: 'Modelo',
      brandEmptyOption: 'Seleccioná una marca',
      modelEmptyOption: 'Seleccioná modelo y versión',
      maxAmount: 'Maximo prestable: ${0}',
      maxAmountHint: 'El cliente deberá seleccionar un monto igual o inferior al máximo prestable.',
      maxAmountTooltip: 'El máximo prestable es el mínimo entre el monto total disponible para el cliente y el valor del vehículo.',
      brandListError: 'Ocurrió un error al intentar obtener el listado de marcas',
      modelListError: 'Ocurrió un error al intentar obtener el listado de modelos y versiones',
      priceError: 'Ocurrió un error al intentar obtener el precio del vehiculo',
      priceLowerThanMin: 'Modelo no disponible para prestamo prendario',
    },
  },

  FinalPage: {
    approved: 'Aprobado',
    preApproved: 'Pre-Aprobado',
    creditNumber: 'Crédito N° ${0}',
    LoanData: {
      date: 'Fecha: <b>${0}</b>',
      name: 'Nombre: <b>${0}</b>',
      dni: 'DNI: <b>${0}</b>',
      requestedAmount: 'Monto solicitado',
      numberOfInstallments: 'Cantidad de cuotas',
      installmentAmount: 'Monto cuota',
    },
    Messages: {
      Manual: {
        Success: {
          isPrepaid: 'Recordale al cliente que podrá utilizar la tarjeta luego de activarla en autogestión.',
          isNotProductToPayVehicleType: 'El préstamo se desembolsará cuando se realice la llamada de bienvenida exitosamente.',
          isNotPledgeDocCompleted: 'Se realizará una bienvenida al cliente de parte de Credicuotas para verificar esta operación y así podrás proceder con la gestión del crédito prendario.',
          isPledgeDocCompleted: 'Se verificará la documentación del préstamo prendario. Cuando esté verificada, entrégasela a tu comercial.',
        },
        Warning: {
          title: 'No se completó el proceso de carga de la solicitud.',
          subtitle: 'Para <strong>LIQUIDAR</strong> el préstamo se requiere:',
          reqCustomerDataCompleted: 'Completar los datos de contacto del cliente.',
          reqFileOrFollowingImages: 'Enviar legajo físico a Credicuotas o subir las siguientes imágenes.',
          reqSign: 'Enviar legajo físico a Credicuotas o subir las siguientes imágenes.',
          waitCall: 'Esperar el llamado de Bienvenida exitoso al cliente.',
        },
      },
      AutomaticWelcome: {
        title: 'ATENCIÓN - BIENVENIDA AUTOMÁTICA',
        subtitle: 'Para finalizar la solicitud, es necesario una verificación. <br /> Por favor <b>indicale al cliente</b> que:',
        checkWhatsappMessages: '<b>Revise </b> sus mensajes de whatsapp.',
        answerQuestions: '<b>Conteste unas preguntas</b> en los próximos <b>10 minutos.</b>',
      },
      AutomaticLiq: {
        title: 'La verificación de identidad del cliente fue exitosa.',
        deliverProduct: 'Puede realizar la entrega del Producto al Cliente.',
        disburseAmount: 'El préstamo será desembolsado en la cuenta del cliente en minutos.',
      },
      rejected: 'El análisis crediticio rechazó la solicitud y no podrá ser completada.',
      warnIfPaperless: 'RECUERDE enviar el legajo físico para evitar inconvenientes en el proceso.',
    },
    CTA: {
      goHome: 'Ir al inicio',
      downloadVoucher: 'Descargar Cuponera',
      downloadDocs: 'Descargar documentación',
    },
  },

  NPSForm: {
    completedTitle: '¡Gracias por responder!',
    completedSubTitle: 'La tendremos en cuenta para mejorar tu experiencia con Credicuotas.',
    buttonAnswered: 'Entendido',
    buttonSend: 'Enviar',
    buttonOmit: 'Omitir',
    title: 'Tu opinión nos interesa',
    noLikely: 'Muy insatisfecho',
    veryLikely: 'Muy satisfecho',
  },

  NPS: {
    LABEL_QUESTION_1: '¿Cuál es tu nivel de satisfacción con la plataforma de ventas de préstamos?',
    LABEL_QUESTION_2: '¿Qué mejorarías de la plataforma teniendo en cuenta tu uso cotidiano?',
    LABEL_ANSWER_2: '',
  },

  StepHeader: {
    title: 'Datos de la solicitud',
    idCredit: 'Número de crédito: <b>${0}<b/>',
    product: 'Producto: <b>${0}</b>',
    customerName: 'Nombre y apellido: <b>${0}</b>',
    customerTaxId: 'CUIT/CUIL: <b>${0}</b>',
    requestedAmount: 'Solicitado a liquidar: <b>${0}</b>',
    cancelAmount: 'Cancelando: <b>${0}</b>',
    paymentPlan: 'Plan de pago: <b>${0} cuotas de ${1}</b>',
    vehicle: 'Vehículo: <b>${0} (${1})</b>',
    advanceAmount: 'Monto anticipo: <b>${0}</b>',
  },

  InstallmentCard: {
    installments: '${0} cuotas',
    cadecom: 'Beneficio CADECOM',
    discount: '<b>${0}%</b> más barato',
  },

  CustomerData: {
    comboBoxDefaulOption: 'Seleccione una opción',
    validateEmail: 'Validar email',
    validateCellphone: 'Validar celular',
    verificationCode: 'Código',
    verificationCodeNotVerified: 'Sin verificar',
    verificationCodeVerified: 'Verificado OK',
    verificationCodeIncorrect: 'Código incorrecto',
    sendCodeError: 'No se pudo enviar el código',
    reviewInputEmail: 'Revisá el mail ingresado y validalo nuevamente.',
    verificationCodeError: 'Error al validar el código',
    countdownSms: 'Se envió el código al cliente por SMS. <br/>Ingreselo a continuación. <br/>(Aguarde ${0} segundos para reenviar)',
    countdownEmail: 'Se envió el código al cliente por email. <br/>Ingreselo a continuación. <br/>(Aguarde ${0} segundos para reenviar)',
    dcNumber: 'Número de Tarjeta',
    dcName: 'Nombre y apellido',
    dcNameHint: 'Como aparece en la tarjeta',
    dcExpiration: 'Vencimiento',
    dcExpirationHint: 'MM/YY',
    dcCvv: 'Código de seg.',
    dcCvvHint: 'Los 3 dígitos del dorso',
    ctaValidateCard: 'Validar tarjeta',
    ctaValidateCardAndCollectAdvance: 'Validar tarjeta y cobrar anticipo',
    debitCardValidationInput: 'Por favor completá correctamente los campos correspondientes a la tarjeta indicados en rojo para poder validarla',
    debitCardValidated: 'Validamos exitosamente tu tarjeta de débito.',
    debitCardRegistrated: 'Registramos correctamente tu tarjeta de débito.',
    debitCardValidationCode1: 'No pudimos registrar los datos de tu tarjeta, aún así podés continuar con la solicitud. En el próximo paso te pediremos una foto de la misma.',
    debitCardValidationCode3: 'Esta tarjeta de débito ya fue utilizada para otra persona, utilice otra tarjeta  o solicite asistencia para continuar.',
    debitCardValidationCode5: 'Superaste la cantidad de intentos. Por favor probá con otra tarjeta.',
    debitCardValidationCodeUnknown: 'No pudimos validar tu tarjeta. Por favor revisa los datos cargados y volvé a intentarlo hasta poder validarla. De lo contrario, podes probar con otra tarjeta.',
    debitCardValidationError: 'No pudimos validar tu tarjeta porque el servicio está inactivo. Por favor volvé a intentarlo en unos minutos.',
    debitCardAlreadyValidated: 'La tarjeta ${0} cargada anteriormente fue validada correctamente',
    debitCardAlreadyLoadButInvalid: 'No pudimos registrar los datos de la tarjeta cargada anteriormente, aún así podés continuar con la solicitud. En el próximo paso te pediremos un foto de la misma.',
    debitCardDownPaymentInfo: [
      'Información para el cliente',
      'Se cobrarán en este momento ${0} en concepto de anticipo en la tarjeta de débito. Debe tener esos fondos en su cuenta para poder avanzar con el préstamo.',
    ],
    debitCardInputTitle: 'Ingresá los datos de la tarjeta',
    debitCardDownPaymentValidated: 'Validamos tu tarjeta y realizamos el adelanto exitosamente.',
    debitCardDownPaymentCode1: 'Detectamos un problema en el servicio de cobro de anticipos.',
    debitCardDownPaymentCode2or3: 'Las condiciones del préstamo no permiten cobrar un anticipo.',
    debitCardDownPaymentCode4: 'Ya se cobró un anticipo para este crédito.',
    debitCardDownPaymentCode5: 'La tarjeta utilizada para cobrar está asociada a otro cliente de Credicuotas.',
    debitCardDownPaymentCode6: 'El cobro del anticipo fue rechazado por la procesadora de pagos, por favor revise si los datos de tarjeta de débito están bien, si tiene fondos suficientes, si no ha superado su limite diario o si la tarjeta se encuentra bloqueada.',
    debitCardDownPaymentCode7: 'Hubo un error con el cobro del anticipo por parte de la procesadora de pagos, por favor revise si los datos de la tarjeta de débito están bien, si tiene fondos suficientes y si no ha superado su limite diario o si la tarjeta se encuentra bloqueada.',
    debitCardDownPaymentCode8: 'Tu tarjeta no tiene fondos suficientes. Por favor, probá con otra tarjeta.',
    cta: 'Continuar',
    disbursementPrePaidCardMailRequired: 'El email es obligatorio para desembolso en tarjeta prepaga',
    disbursementPrePaidCardZipCodeRequired: 'El codigo postal es obligatorio para desembolso en tarjeta prepaga',
  },

  DocumentUploadPage: {
    title: 'Cargá la documentación',
    description: 'Elegí cómo cargar la documentación del cliente.',
    tabOptions: [
      'Enviar link de validación al cliente',
      'Validar al cliente desde acá',
    ],
    tab1: {
      description: 'Cargá el celular del cliente para que continue con su validación y finalice el proceso del préstamo.',
      steps: [
        { icon: '/images/step-sms.svg', description: 'Abre el link en su celular' },
        { icon: '/images/step-doc.svg', description: 'Sube la documentación' },
        { icon: '/images/step-identity.svg', description: 'Valida su identidad' },
        { icon: '/images/step-sign.svg', description: 'Firma el contrato' },
      ],
      cellphoneInput: 'Celular',
      cellphoneInputHint: 'Sin el 0 ni el 15',
      ctaSendLink: 'Enviar link',
    },
    tab2: {
      qrOption: 'Escaneá el código QR con tu celular para continuar con la carga de la documentación del cliente.',
      linkOption: 'Enviar un link a tu celular para continuar con la carga de documentación del cliente.',
      linkOptionDescription: 'Cargá el número de tu celular para continuar con la validación y finalizar el proceso del préstamo.',
      cellphoneInput: 'Celular del vendedor',
      cellphoneInputHint: 'Sin el 0 ni el 15',
      ctaSendLink: 'Enviar link',
    },
  },

  ManageBranchesPage: {
    title: 'Gestionar sucursales',
    description: 'Podés agregar perfiles en la sucursal.',
    sectionTitles: [
      'Elegí la sucursal y el tipo de nuevo perfil',
      'Completá los datos del nuevo perfil',
      'Perfiles agregados recientemente',
    ],
    inputBranch: 'Sucursal',
    inputProfile: 'Perfil',
    inputName: 'Nombre',
    inputSurname: 'Apellido',
    inputIdentifier: 'Nro. de DNI',
    inputIdentifierHint: 'Sin espacios ni puntos',
    inputPhone: 'Celular',
    inputPhoneHint: 'Sin el 0 ni el 15',
    inputEmail: 'Email',
    cta: 'Agregar vendedor',
    roles: [
      { id: ROLE_SELLER, label: 'Vendedor' },
      { id: ROLE_MANAGER, label: 'Admin' },
    ],
    tableHeaders: [
      'Nombre y apellido',
      'DNI',
      'Celular',
      'Email',
      'Perfil',
      'Sucursal',
      'Estado',
    ],
    emptySellerList: [
      'Todavía no agregaste perfiles',
      'Cargá perfiles para poder visualizarlos.',
    ],
    ctaEdit: 'Editar',
    ctaCancel: 'Cancelar',
  },

  SignaturePage: {
    title: 'Firma de contrato',
    description: 'Para continuar tenés que firmar con tu dedo dentro del recuadro. Esta firma será incluida en el contrato.',
    sendAgreementTitle: 'Enviar contrato al cliente',
    sendAgreementDescription: 'Enviá el link del contrato al celular del cliente.',
    sendAgreementCTA: 'Enviar contrato',
    cellphoneInput: 'Celular',
    cellphoneInputHint: 'Sin el 0 ni el 15',
    agreementSentMessage: 'Se envio el contrato por SMS al cliente',
    repeatSignature: 'Repetir firma',
    signPlaceholder: 'Hace click para firmar',
    signCTA: 'Confirmar',
  },

  // loading svg
  loadingSVG1: 'M61.5 69.5H29c-4.1 0-7.4-3.3-7.4-7.4V29.7c0-4.1 3.3-7.4 7.4-7.4h32.4c4.1 0 7.4 3.3 7.4 7.4v32.4c.1 4.1-3.2 7.4-7.3 7.4z',
  loadingSVG2: 'M61.5 69.5H29c-4.1 0-7.4-3.3-7.4-7.4V29.7c0-4.1 3.3-7.4 7.4-7.4h32.4c4.1 0 7.4 3.3 7.4 7.4v32.4c.1 4.1-3.2 7.4-7.3 7.4z',
  loadingSVG3: 'M55.2 46.9c-.4-.8-.9-1.4-1.6-1.9-.7-.5-1.5-.9-2.3-1.2-.9-.3-1.8-.5-2.7-.6l-3.8-.5c-.9-.1-1.6-.3-2-.5-.4-.2-.7-.5-.7-1s.2-.9.7-1.2c.5-.3 1.4-.4 2.6-.4 1.1 0 2.1.1 3 .3.9.2 1.6.4 2.3.6.6.2 1.1.4 1.5.6.4.2.6.4.7.5.1 0 .2-.1.4-.3.2-.1.4-.3.7-.6l.6-.9c.2-.3.3-.7.3-1.2 0-.6-.2-1.1-.5-1.5-.3-.4-.7-.7-1.3-1-.9-.5-2-.8-3.3-1-.4-.1-.8-.1-1.3-.2V34c0-.9-.8-1.7-1.7-1.7h-3c-.9 0-1.7.8-1.7 1.7v1c-.5.1-.9.2-1.3.3-1.3.4-2.3.9-3.1 1.5-.8.7-1.4 1.4-1.8 2.3-.4.9-.6 1.8-.6 2.9 0 1.2.2 2.2.7 3.1.4.8 1 1.5 1.8 2s1.7.9 2.7 1.2c1 .3 2 .5 3.1.6l2.2.2c1 .1 1.8.3 2.3.5.5.2.7.6.7 1.2 0 .5-.3.9-.9 1.1-.6.2-1.5.3-2.6.3-1 0-2-.1-2.9-.2-.9-.1-1.7-.3-2.4-.5s-1.3-.4-1.8-.7c-.5-.2-.9-.4-1.1-.6l-2.4 4.3c.2.2.6.4 1.2.7.6.3 1.3.6 2.2.9.9.3 2 .5 3.3.8.2 0 .4.1.7.1v.9c0 .9.8 1.7 1.7 1.7h3c.9 0 1.7-.8 1.7-1.7V57c1.9-.3 3.5-.9 4.7-1.8 1.8-1.3 2.7-3.1 2.7-5.5 0-1.1-.3-2.1-.7-2.8z',
};

export default i18n;
