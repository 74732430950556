import { Navigate, Route, Routes } from 'react-router';
import LoggedRoute from './LoggedRoute';
import DashboardLayoutComponent from '../pages/dashboard/components/DashboardLayoutComponent';
import {
  ClubCredicuotas, Comisiones, Home, Liquidaciones, PanelVentas, Renovadores, Solicitudes,
} from '../pages/dashboard/fakePages';
import { privateRoutes } from '../constants/routesConstants';
import RestrictedRoute from './RestrictedRoute';
import { menuRestrictions } from '../utils/menuUtils';
import QuotationPage from '../pages/dashboard/quotationPage';
import InstallmentPlanPage from '../pages/loanRequest/installmentPlanPage';
import CustomerDataPage from '../pages/loanRequest/customerDataPage';
import FinalPage from '../pages/loanRequest/finalPage';
import ManageBranchesPage from '../pages/commerceAffiliation/manageBranchesPage';
import DocumentUploadPage from '../pages/loanRequest/documentUploadPage';
import SignaturePage from '../pages/loanRequest/signaturePage';

const ProtectedRoutes = () => (
  <Routes>
    <Route path="/" element={<LoggedRoute><DashboardLayoutComponent /></LoggedRoute>}>
      <Route
        path={privateRoutes.home}
        element={<RestrictedRoute condition={menuRestrictions[privateRoutes.home]} element={<Home />} />}
      />
      <Route
        path={privateRoutes.newLoanRequest}
        element={<RestrictedRoute condition={menuRestrictions[privateRoutes.newLoanRequest]} element={<QuotationPage />} />}
      />
      <Route
        path={privateRoutes.loanRequests}
        element={<RestrictedRoute condition={menuRestrictions[privateRoutes.loanRequests]} element={<Solicitudes />} />}
      />
      <Route
        path={privateRoutes.renewers}
        element={<RestrictedRoute condition={menuRestrictions[privateRoutes.renewers]} element={<Renovadores />} />}
      />
      <Route
        path={privateRoutes.liquidations}
        element={<RestrictedRoute condition={menuRestrictions[privateRoutes.liquidations]} element={<Liquidaciones />} />}
      />
      <Route
        path={privateRoutes.commission}
        element={<RestrictedRoute condition={menuRestrictions[privateRoutes.commission]} element={<Comisiones />} />}
      />
      <Route
        path={privateRoutes.sales}
        element={<RestrictedRoute condition={menuRestrictions[privateRoutes.sales]} element={<PanelVentas />} />}
      />
      <Route
        path={privateRoutes.credicuotasClub}
        element={<RestrictedRoute condition={menuRestrictions[privateRoutes.credicuotasClub]} element={<ClubCredicuotas />} />}
      />
      <Route
        path={privateRoutes.manageBranches}
        element={<RestrictedRoute condition={menuRestrictions[privateRoutes.manageBranches]} element={<ManageBranchesPage />} />}
      />
      <Route path={privateRoutes.installmentPlan} element={<InstallmentPlanPage />} />
      <Route path={privateRoutes.customerData} element={<CustomerDataPage />} />
      <Route path={privateRoutes.documentUpload} element={<DocumentUploadPage />} />
      <Route path={privateRoutes.signature} element={<SignaturePage />} />
      <Route path={privateRoutes.final} element={<FinalPage />} />
      <Route path="/" element={<Navigate to={privateRoutes.home} replace />} />
    </Route>
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
);

export default ProtectedRoutes;
