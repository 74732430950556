import PropTypes from 'prop-types';
import { Grid, styled } from '@mui/material';
import Loading from '../../../components/commons/Loading';
import { LOGIN_BACKGROUND } from '../../../constants/imageConstants';

const RootGrid = styled(Grid)(({ theme }) => ({
  backgroundImage: `url("${LOGIN_BACKGROUND}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '120vh',

  [theme.breakpoints.down('md')]: {
    backgroundImage: 'none',
  },
}));

const LoginLayoutComponent = ({ loading, children }) => (
  <RootGrid container>
    <Grid item xs={1} />
    <Grid item xs={10} sm={10} md={4} display="flex" flexDirection="column" alignItems="flex-start">
      {loading ? (
        <Loading />
      ) : (
        <>
          {children}
        </>
      )}
    </Grid>
  </RootGrid>
);

LoginLayoutComponent.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

LoginLayoutComponent.defaultProps = {
  loading: false,
};

export default LoginLayoutComponent;
