import { fetch } from './axiosInstance';

const BASE_URL = '/commerce-affiliation-user-requests';

// eslint-disable-next-line import/prefer-default-export
export const createBranchUser = (user) =>
  fetch({
    url: BASE_URL,
    method: 'post',
    data: user,
  });
