import * as yup from 'yup';
import i18n from '../common/i18n';

// eslint-disable-next-line import/prefer-default-export
export const manageBranchesSchemaValidation = yup.object().shape({
  branch: yup.object().required(''),
  profile: yup.string().required(''),
  name: yup.string().required(''),
  surname: yup.string().required(''),
  identifier: yup.string()
    .matches(/^[0-9]{6,9}$/, { excludeEmptyString: true, message: i18n.schemaValidation.customerId.length })
    .required(i18n.schemaValidation.customerId.required),
  phone: yup.string()
    .matches(/^[0-9]+$/, i18n.ManageBranchesPage.inputPhoneHint)
    .length(10, '')
    .required(i18n.ManageBranchesPage.inputPhoneHint),
  email: yup.string()
    .email(i18n.schemaValidation.email.format)
    .required(''),
});
