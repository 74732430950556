import { CardHeader as VulpinoCardHeader } from '@doit/pcnt-react-ui-library';
import { styled } from '@mui/material';

const CardHeader = styled(VulpinoCardHeader)(() => ({
  textAlign: 'left',
  '& .MuiTypography-root': {
    fontWeight: '700',
    color: '#424242',
    fontSize: '20px',
  },
}));

export default CardHeader;
