import { useState } from 'react';
import PropTypes from 'prop-types';
import { CardContent } from '@doit/pcnt-react-ui-library';
import {
  Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CircleIcon from '@mui/icons-material/Circle';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Card from '../../../components/commons/Card';
import i18n from '../../../common/i18n';
import { SELLER_STATUS } from '../../../constants/commerceAffiliationRequestConstants';

const ListBranch = ({ sellerList }) => {
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl();
  };

  const handleEdit = (seller) => {
    // TODO: editar solicitud
    console.log(seller);
    setAnchorEl();
  };

  const handleCancel = (seller) => {
    // TODO: cancelar solicitud
    console.log(seller);
    setAnchorEl();
  };

  return (
    <Card sx={{ padding: '32px 24px' }}>
      <CardContent>
        <Typography align="left" sx={{ fontSize: '16px', fontWeight: '600', marginBottom: '32px' }}>
          {i18n.ManageBranchesPage.sectionTitles[2]}
        </Typography>
        {sellerList ? (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{i18n.ManageBranchesPage.tableHeaders[0]}</TableCell>
                  <TableCell>{i18n.ManageBranchesPage.tableHeaders[1]}</TableCell>
                  <TableCell>{i18n.ManageBranchesPage.tableHeaders[2]}</TableCell>
                  <TableCell>{i18n.ManageBranchesPage.tableHeaders[3]}</TableCell>
                  <TableCell>{i18n.ManageBranchesPage.tableHeaders[4]}</TableCell>
                  <TableCell>{i18n.ManageBranchesPage.tableHeaders[5]}</TableCell>
                  <TableCell>{i18n.ManageBranchesPage.tableHeaders[6]}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {sellerList.map((seller) => (
                  <TableRow key={seller.identifier} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>{seller.name}</TableCell>
                    <TableCell>{seller.identifier}</TableCell>
                    <TableCell>{seller.phone}</TableCell>
                    <TableCell>{seller.email}</TableCell>
                    <TableCell>{seller.profile}</TableCell>
                    <TableCell>{seller.branch}</TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <CircleIcon
                          style={{ fontSize: '10px', marginRight: '4px' }}
                          sx={seller.status === SELLER_STATUS.FINISHED.id ? { color: '#02C66A' } : { color: '#F5A623' }}
                        />
                        {SELLER_STATUS[seller.status].description}
                      </Box>
                    </TableCell>
                    {seller.status === SELLER_STATUS.FINISHED.id ? (
                      <TableCell />
                    ) : (
                      <TableCell>
                        <IconButton
                          id="basic-button"
                          aria-controls={open ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={handleClick}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <MenuItem onClick={() => handleEdit(seller)}>
                            <ListItemIcon><BorderColorIcon /></ListItemIcon>
                            <ListItemText>{i18n.ManageBranchesPage.ctaEdit}</ListItemText>
                          </MenuItem>
                          <MenuItem onClick={() => handleCancel(seller)}>
                            <ListItemIcon><DeleteOutlineIcon /></ListItemIcon>
                            <ListItemText>{i18n.ManageBranchesPage.ctaCancel}</ListItemText>
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box sx={{ backgroundColor: '#f5f5f5', padding: '24px' }}>
            <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>{i18n.ManageBranchesPage.emptySellerList[0]}</Typography>
            <Typography sx={{ fontSize: '16px', marginTop: '12px' }}>{i18n.ManageBranchesPage.emptySellerList[1]}</Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

ListBranch.propTypes = {
  sellerList: PropTypes.arrayOf(PropTypes.shape()),
};

ListBranch.defaultProps = {
  sellerList: undefined,
};

export default ListBranch;
