import parse from 'html-react-parser';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Alert, Button } from '@doit/pcnt-react-ui-library';
import TextInputControlled from '../../../components/commons/TextInputControlled';
import i18n from '../../../common/i18n';
import useCountDown from '../../../hooks/useCountDown';
import { fillMessageWith } from '../../../utils/functionsUtils';

const useStyles = makeStyles(() => ({
  notVerified: {
    marginTop: '8px',
    marginLeft: '4px',
    fontSize: '12px',
    color: '#f6ae37',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'stretch',
  },
  verified: {
    marginTop: '8px',
    marginLeft: '4px',
    fontSize: '12px',
    color: '#19cb77',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'stretch',
  },
  incorrect: {
    marginTop: '8px',
    marginLeft: '4px',
    fontSize: '12px',
    color: '#fb2a44',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'stretch',
  },
}));

const CustomerDataInputText = ({
  input, methods, onSendValidationCode, onInputCode,
}) => {
  const TIME = 60;
  const classes = useStyles();

  // Si el campo de celular o mail ya tiene un valor por default, ya aparece el boton de validar habilitado
  // caso contrario estara deshabilitado hasta que se ingrese un valor valido.
  const [sendCodeButtonDisable, setSendCodeButtonDisable] = useState(!input.userInputOptions.defaultValue);
  const [inputCodeDisable, setInputCodeDisable] = useState(true);

  // Cuenta regresiva para celular
  const {
    secondsLeft: cellphoneTime, start: startCellphoneTimer, isActive: isCellphoneTimerActive, reset: resetCellphoneTimer,
  } = useCountDown(TIME);
  // Cuenta regresiva para email
  const {
    secondsLeft: emailTime, start: startEmailTimer, isActive: isEmailTimerActive, reset: resetEmailTimer,
  } = useCountDown(TIME);

  const sendValidationCode = async (inputAux) => {
    const result = await onSendValidationCode(inputAux);
    if (result) {
      // Habilito el input para que ingrese el codigo si se envio correctamente.
      setInputCodeDisable(false);
      // Inicio la cuenta regresiva que corresponda
      if (inputAux.name === 'PtllaMail') {
        startEmailTimer();
      } else {
        startCellphoneTimer();
      }
    }
  };

  const verificationCode = methods.watch(`${input.name}VerificationCode`);

  // Maneja el cambio en el input de celular o mail y resetea la cuenta regresiva
  const handleChange = async () => {
    if (input.name === 'PtllaMail') {
      resetEmailTimer();
      const isValid = await methods.trigger(input.name);
      setInputCodeDisable(true);
      setSendCodeButtonDisable(!isValid);
    } else if (input.name === 'PtllaTelCelular') {
      resetCellphoneTimer();
      const isValid = await methods.trigger(input.name);
      setInputCodeDisable(true);
      setSendCodeButtonDisable(!isValid);
    }
  };

  const renderInput = (gridSize) => (
    <>
      <Grid item xs="auto" md={gridSize}>
        <TextInputControlled
          name={input.name}
          label={input.userInputOptions.label}
          defaultValue={input.userInputOptions.defaultValue}
          variant="outlined"
          helperText={input.userInputOptions.hint}
          mandatory={input.userInputOptions.required}
          onChange={handleChange}
          fullWidth
        />
        {isCellphoneTimerActive && (
          <Alert severity="info" sx={{ marginTop: '8px' }}>
            <Typography align="left" sx={{ fontSize: '0.875rem' }}>
              {parse(fillMessageWith(i18n.CustomerData.countdownSms, cellphoneTime))}
            </Typography>
          </Alert>
        )}
        {isEmailTimerActive && (
          <Alert severity="info" sx={{ marginTop: '8px' }}>
            <Typography align="left" sx={{ fontSize: '0.875rem' }}>
              {parse(fillMessageWith(i18n.CustomerData.countdownEmail, emailTime))}
            </Typography>
          </Alert>
        )}
      </Grid>
      {input.userInputOptions.verification !== 'NONE' && (
        <>
          <Grid item xs="auto" md={3}>
            <Button
              variant="secondary"
              disabled={sendCodeButtonDisable}
              onClick={() => sendValidationCode(input)}
              sx={{ marginTop: '10px', minWidth: '0' }}
              fullWidth
            >
              { input.name === 'PtllaMail' ? i18n.CustomerData.validateEmail : i18n.CustomerData.validateCellphone }
            </Button>
          </Grid>
          <Grid item xs="auto" md={3}>
            <TextInputControlled
              name={`${input.name}Code`}
              label={i18n.CustomerData.verificationCode}
              variant="outlined"
              inputProps={{ maxLength: 4 }}
              onChange={(e) => onInputCode(e.target.value, input)}
              mandatory={input.userInputOptions.verification === 'REQUIRED'}
              disabled={inputCodeDisable}
              fullWidth
            />
            {verificationCode === undefined && (
              <Typography align="left" className={classes.notVerified}>
                <WarningAmberIcon sx={{ fontSize: '16px', marginRight: '4px' }} />
                {i18n.CustomerData.verificationCodeNotVerified}
              </Typography>
            )}
            {verificationCode && (
              <Typography align="left" className={classes.verified}>
                <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '4px' }} />
                {i18n.CustomerData.verificationCodeVerified}
              </Typography>
            )}
            {verificationCode === false && (
              <Typography align="left" className={classes.incorrect}>
                <CheckCircleOutlineIcon sx={{ fontSize: '16px', marginRight: '4px' }} />
                {i18n.CustomerData.verificationCodeIncorrect}
              </Typography>
            )}
          </Grid>
        </>
      )}
    </>
  );

  return (
    <>
      {input.userInputOptions.verification !== 'NONE' ? (
        <Grid item xs={12}>
          <Grid container columnGap="24px" rowGap="32px">
            {/* para input de verificacion de cel o mail con codigo ponemos todos los campos en la misma fila */}
            {renderInput(4)}
          </Grid>
        </Grid>
      ) : (
        // Caso contrario el campo se ajusta automaticamente
        <>{renderInput('auto')}</>
      )}
    </>
  );
};

CustomerDataInputText.propTypes = {
  input: PropTypes.shape().isRequired,
  methods: PropTypes.shape().isRequired,
  onSendValidationCode: PropTypes.func.isRequired,
  onInputCode: PropTypes.func.isRequired,
};

export default CustomerDataInputText;
