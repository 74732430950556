import qs from 'qs';
import { fetch } from './axiosInstance';

export const login = (credentials) =>
  fetch({
    url: '/signin/authenticate',
    method: 'POST',
    data: qs.stringify(credentials),
    headers: { 'content-type': 'application/x-www-form-urlencoded; charset=UTF-8' },
  });

export const authenticate = () =>
  fetch({
    url: '/authentication',
    method: 'get',
  });

export const askPasswordReset = (username) =>
  fetch({
    url: `/resetPasswordEmail/${username}`,
    method: 'get',
  });

export const resetPassword = (hash) =>
  fetch({
    url: `/resetPasswordExecute/${hash}`,
    method: 'put',
  });

export const updatePassword = (credentials) =>
  fetch({
    url: '/updatePasswordExecute',
    method: 'put',
    data: credentials,
  });

export const logout = () =>
  fetch({
    url: '/logout',
    method: 'get',
  });
