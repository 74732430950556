import {
  useEffect, useRef, useState,
} from 'react';

const useCountDown = (initialSeconds) => {
  const [secondsLeft, setSecondsLeft] = useState(initialSeconds);
  const [isActive, setIsActive] = useState(false);
  const intervalRef = useRef(null);

  const start = (time) => {
    if (!isActive) {
      setIsActive(true);
    }
    if (time) {
      setSecondsLeft(time);
    } else {
      setSecondsLeft(initialSeconds);
    }
  };

  const reset = () => {
    setIsActive(false);
    setSecondsLeft(initialSeconds);
  };

  useEffect(() => {
    if (isActive && secondsLeft > 0) {
      intervalRef.current = setInterval(() => {
        setSecondsLeft((prev) => prev - 1);
      }, 1000);
    } else if (secondsLeft === 0) {
      setIsActive(false);
    }

    return () => clearInterval(intervalRef.current);
  }, [isActive, secondsLeft]);

  return {
    secondsLeft, isActive, start, reset,
  };
};

export default useCountDown;
