import parse from 'html-react-parser';
import { Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Alert, Button } from '@doit/pcnt-react-ui-library';
import i18n from '../../common/i18n';
import { forgotPasswordSchemaValidation } from '../../forms/loginSchemaValidations';
import TextInputControlled from '../../components/commons/TextInputControlled';
import useLoginController from '../../hooks/controllers/useLoginController';
import { fillMessageWith } from '../../utils/functionsUtils';
import { LOGIN_CREDICUOTAS_LOGO } from '../../constants/imageConstants';
import LoginLayoutComponent from './components/LoginLayoutComponent';

const ForgotPasswordPage = () => {
  const {
    loading, doRecover, goToLogin, wasRestored, customerEmail, authenticationError,
  } = useLoginController();
  const methods = useForm({
    resolver: yupResolver(forgotPasswordSchemaValidation),
  });

  return (
    <LoginLayoutComponent>
      <img src={LOGIN_CREDICUOTAS_LOGO} alt="Credicuotas" style={{ marginTop: '64px', marginBottom: '28px' }} />
      {wasRestored ? (
        <>
          <Typography variant="h1" align="left" sx={{ marginBottom: '12px' }}>
            {i18n.ForgotPassword.title[1]}
          </Typography>
          <Typography align="left" sx={{ marginBottom: '12px' }}>
            {parse(fillMessageWith(i18n.ForgotPassword.description[1], customerEmail))}
          </Typography>
          <Typography align="left" sx={{ marginBottom: '28px' }}>
            {parse(i18n.ForgotPassword.description[2])}
          </Typography>
          <Button loading={loading} variant="primary" onClick={goToLogin} fullWidth sx={{ marginTop: '24px' }}>
            {i18n.ForgotPassword.initSessionCTA}
          </Button>
        </>
      ) : (
        <>
          <Typography variant="h1" align="left" sx={{ marginBottom: '12px' }}>
            {i18n.ForgotPassword.title[0]}
          </Typography>
          <Typography align="left" sx={{ marginBottom: '28px' }}>
            {i18n.ForgotPassword.description[0]}
          </Typography>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(doRecover)}>
              {authenticationError && (
                <Alert severity="error" sx={{ marginBottom: '28px' }}>
                  <Typography align="left" sx={{ fontSize: '14px' }}><b>{authenticationError.description}</b></Typography>
                </Alert>
              )}
              <TextInputControlled
                name="user"
                label={i18n.ForgotPassword.userInput}
                variant="outlined"
                fullWidth
                mandatory
                sx={{ marginBottom: '12px' }}
              />
              <Button loading={loading} variant="primary" type="submit" fullWidth sx={{ marginTop: '24px' }}>
                {i18n.ForgotPassword.recoverCTA}
              </Button>
              <Button loading={loading} variant="secondary" onClick={goToLogin} fullWidth sx={{ marginTop: '16px' }}>
                {i18n.ForgotPassword.cancelCTA}
              </Button>
            </form>
          </FormProvider>
        </>
      )}
    </LoginLayoutComponent>
  );
};

export default ForgotPasswordPage;
