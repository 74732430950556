import * as yup from 'yup';
import i18n from '../common/i18n';

export const defaultComboBoxOption = { id: 'none', label: i18n.CustomerData.comboBoxDefaulOption };

export const customerDataSchemaValidation = (inputGroups) => {
  let dynamicSchemaValidation = {};

  inputGroups?.forEach((inputGroup) => {
    inputGroup.variables.forEach((input) => {
      // por default considero que es string
      let validations = yup.string().nullable();

      if (input.userInputOptions.type === 'TYPEAHEAD') {
        // Si es typeahead, lo piso como objeto
        validations = yup.object({ id: yup.string().notOneOf([defaultComboBoxOption.id], '') }).nullable();
      }

      if (input.userInputOptions.required) {
        validations = validations.required('');
      }

      if (input.userInputOptions.type === 'COMBO') {
        validations = validations.notOneOf([defaultComboBoxOption.id], '');
      }

      if (input.userInputOptions.pattern) {
        validations = validations.matches(
          new RegExp(input.userInputOptions.pattern), { excludeEmptyString: true, message: 'formato invalido' },
        );
      }

      dynamicSchemaValidation = {
        ...dynamicSchemaValidation,
        [input.name]: validations,
      };
    });
  });

  return yup.object().shape(dynamicSchemaValidation);
};
