import { Typography } from '@mui/material';
import { Alert, Button } from '@doit/pcnt-react-ui-library';
import { LOGIN_CREDICUOTAS_LOGO } from '../../constants/imageConstants';
import i18n from '../../common/i18n';
import useRestorePasswordController from '../../hooks/controllers/useRestorePasswordController';
import LoginLayoutComponent from './components/LoginLayoutComponent';

const RestorePasswordPage = () => {
  const {
    globalLoading, restoreError, goToLogin,
  } = useRestorePasswordController();

  return (
    <LoginLayoutComponent loading={globalLoading}>
      <img src={LOGIN_CREDICUOTAS_LOGO} alt="Credicuotas" style={{ marginTop: '64px', marginBottom: '28px' }} />
      {restoreError ? (
        <Alert severity="error">
          <Typography align="left" sx={{ fontSize: '14px' }}><b>{restoreError.description}</b></Typography>
        </Alert>
      ) : (
        <>
          <Typography variant="h1" align="left" sx={{ marginBottom: '12px' }}>
            {i18n.RestorePassword.title}
          </Typography>
          <Typography align="left" sx={{ marginBottom: '12px' }}>
            {i18n.RestorePassword.description}
          </Typography>
        </>
      )}
      <Button variant="primary" onClick={goToLogin} fullWidth sx={{ marginTop: '32px' }}>
        {i18n.Login.loginCTA}
      </Button>
    </LoginLayoutComponent>
  );
};

export default RestorePasswordPage;
