import * as yup from 'yup';
import i18n from '../common/i18n';

// eslint-disable-next-line import/prefer-default-export
export const quotationSchemaValidation = (mustRequestHowMeetUs) => yup.object().shape({
  customerId: yup.string()
    .matches(/^[0-9]{6,9}$/, { excludeEmptyString: true, message: i18n.schemaValidation.customerId.length })
    .required(i18n.schemaValidation.customerId.required),
  product: yup.string()
    .required(i18n.schemaValidation.product.required)
    .notOneOf(['none'], i18n.schemaValidation.product.required),
  subproduct: yup.string()
    .required(i18n.schemaValidation.subproduct.required)
    .notOneOf(['none'], i18n.schemaValidation.subproduct.required),
  terms: yup.string().required(i18n.schemaValidation.terms.required),
  customerTaxId: yup.string()
    .required(i18n.schemaValidation.customerTaxId.required)
    .notOneOf(['none'], i18n.schemaValidation.customerTaxId.required),
  howMeetUs: yup.lazy(() => {
    if (mustRequestHowMeetUs) {
      return yup.string()
        .required(i18n.schemaValidation.howMeetUs.required)
        .notOneOf(['none'], i18n.schemaValidation.howMeetUs.required);
    }
    return yup.string().notRequired();
  }),
});
