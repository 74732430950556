export const Home = () => (
  <>Home</>
);

export const NuevaSolicitud = () => (
  <>Nueva Solicitud</>
);

export const Solicitudes = () => (
  <>Solicitudes</>
);

export const Renovadores = () => (
  <>Renovadores</>
);

export const Liquidaciones = () => (
  <>Liquidaciones</>
);

export const Comisiones = () => (
  <>Comisiones</>
);

export const PanelVentas = () => (
  <>PanelVentas</>
);

export const ClubCredicuotas = () => (
  <>ClubCredicuotas</>
);
