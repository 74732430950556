import { fetch } from './axiosInstance';

export const getVehicleBrands = () =>
  fetch({
    url: '/vehicles/brands',
    method: 'get',
  });

export const getVehicleModels = (brandId) =>
  fetch({
    url: `/vehicles/brands/${brandId}/models`,
    method: 'get',
  });

export const getVehiclePrice = (identifierId) =>
  fetch({
    url: `/vehicles/prices/identifiers/${identifierId}`,
    method: 'get',
  });
