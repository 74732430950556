/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
import i18n from '../common/i18n';
import { MENU_ICON_EMPTY, MENU_ICON_HOME, MENU_ICON_LOANS, MENU_ICON_NEW_LOAN, MENU_ICON_RENEWERS, MENU_ICON_ACCOUNT } from '../constants/imageConstants';
import { ROLE_ADMIN, ROLE_COMMISION_FEE, ROLE_SALES_MANAGER } from '../constants/rolesConstants';
import { privateRoutes } from '../constants/routesConstants';

export const MENU_WIDTH_SIZE = '300px';

export const menuRestrictions = {
  [privateRoutes.home]: () => true,
  [privateRoutes.newLoanRequest]: () => true,
  [privateRoutes.loanRequests]: () => true,
  [privateRoutes.renewers]: (user) => user.principal.infoLogin.hasRenewers,
  [privateRoutes.liquidations]: (user) => user.authorities.some(({ authority }) => authority === ROLE_ADMIN || authority === ROLE_SALES_MANAGER),
  [privateRoutes.commission]: (user) => user.authorities.some(({ authority }) => authority === ROLE_COMMISION_FEE),
  [privateRoutes.sales]: (user) => user.authorities.some(({ authority }) => authority === ROLE_SALES_MANAGER),
  [privateRoutes.credicuotasClub]: (user) => user.principal.infoLogin.participaClubCredicuotas,
  [privateRoutes.manageBranches]: (user) => user.authorities.some(({ authority }) => authority === ROLE_ADMIN || authority === ROLE_SALES_MANAGER),
};

export const menuConfig = [
  {
    key: 1,
    options: [
      { label: i18n.SideMenu.options.home, icon: MENU_ICON_HOME, path: privateRoutes.home, isAllowed: menuRestrictions[privateRoutes.home] },
      { label: i18n.SideMenu.options.newLoanRequest, icon: MENU_ICON_NEW_LOAN, path: privateRoutes.newLoanRequest, isAllowed: menuRestrictions[privateRoutes.newLoanRequest] },
      { label: i18n.SideMenu.options.loanRequests, icon: MENU_ICON_LOANS, path: privateRoutes.loanRequests, isAllowed: menuRestrictions[privateRoutes.loanRequests] },
      { label: i18n.SideMenu.options.renewers, icon: MENU_ICON_RENEWERS, path: privateRoutes.renewers, isAllowed: menuRestrictions[privateRoutes.renewers] },
    ],
  },
  {
    key: 2,
    section: { title: i18n.SideMenu.sections.account, icon: MENU_ICON_ACCOUNT },
    options: [
      { label: i18n.SideMenu.options.liquidations, icon: MENU_ICON_EMPTY, path: privateRoutes.liquidations, isAllowed: menuRestrictions[privateRoutes.liquidations] },
      { label: i18n.SideMenu.options.commission, icon: MENU_ICON_EMPTY, path: privateRoutes.commission, isAllowed: menuRestrictions[privateRoutes.commission] },
      { label: i18n.SideMenu.options.sales, icon: MENU_ICON_EMPTY, path: privateRoutes.sales, isAllowed: menuRestrictions[privateRoutes.sales] },
      { label: i18n.SideMenu.options.manageBranches, icon: MENU_ICON_EMPTY, path: privateRoutes.manageBranches, isAllowed: menuRestrictions[privateRoutes.manageBranches] },
    ],
  },
];
