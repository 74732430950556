import {
  FormControl, FormHelperText, InputLabel, MenuItem, Select,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

const ComboBoxControlled = ({
  name, label, options, mandatory, helperText, defaultValue, onSelectOption, ...props
}) => {
  const { control, formState: { errors } } = useFormContext();

  return (
    <FormControl fullWidth error={!!(errors && errors[name])}>
      <InputLabel id={`${name}-select-label`}>
        {mandatory ? `${label} *` : label}
      </InputLabel>
      <Controller
        {...props}
        name={name}
        control={control}
        defaultValue={defaultValue || options[0].id}
        render={({ field }) => (
          <Select
            {...field}
            labelId={`${name}-select-label`}
            id={`${name}-select`}
            value={field.value || defaultValue || options[0].id}
            label={mandatory ? `${label} *` : label}
            sx={{ minWidth: '216px' }}
            onChange={(e) => {
              field.onChange(e.target.value);
              onSelectOption(e.target.value);
            }}
          >
            {options.map((option) => (
              <MenuItem value={option.id} key={option.id}>{option.label}</MenuItem>
            ))}
          </Select>
        )}
      />
      {errors && errors[name] && (
        <FormHelperText>{errors[name].message}</FormHelperText>
      )}
    </FormControl>
  );
};

ComboBoxControlled.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  onSelectOption: PropTypes.func,
  defaultValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
  variant: PropTypes.string,
  helperText: PropTypes.string,
};

ComboBoxControlled.defaultProps = {
  defaultValue: '',
  mandatory: false,
  variant: 'standard',
  helperText: undefined,
  onSelectOption: () => {},
};

export default ComboBoxControlled;
