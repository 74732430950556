import { Avatar, Box, Typography } from '@mui/material';
import { useAuth } from '../../../contexts/AuthContext';

const MenuAvatar = () => {
  const { loggedUser } = useAuth();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Avatar>
        {loggedUser.principal.infoLogin.nombre.slice(0, 1)}
        {loggedUser.principal.infoLogin.apellido.slice(0, 1)}
      </Avatar>
      <Box sx={{ paddingLeft: '8px' }}>
        <Typography align="left" sx={{ textTransform: 'capitalize', fontWeight: '600' }}>
          {`${loggedUser.principal.infoLogin.nombre.toLowerCase()} ${loggedUser.principal.infoLogin.apellido.toLowerCase()}`}
        </Typography>
        <Typography align="left" sx={{ color: '#424242', fontSize: '12px' }}>
          {loggedUser.principal.infoLogin.nombrecomercio.toUpperCase()}
        </Typography>
      </Box>
    </Box>
  );
};

export default MenuAvatar;
