import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { updatePassword } from '../../api/authenticationApiService';
import { getAuthenticationError } from '../../services/errorApiService';
import useNavigation from '../useNavigation';

const useExpiredPasswordController = () => {
  const location = useLocation();
  const { goToLoginPage } = useNavigation();

  const [loading, setLoading] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(true);
  const [updateError, setUpdateError] = useState();
  const [updateSuccess, setUpdateSuccess] = useState();

  const goToLogin = () => {
    goToLoginPage();
  };

  const doUpdatePassword = async (data) => {
    try {
      setLoading(true);
      const credentials = {
        username: location.state.username,
        oldPassword: location.state.password,
        newPassword: data.newPass,
      };
      await updatePassword(credentials);
      setUpdateSuccess(true);
    } catch (error) {
      setUpdateError(getAuthenticationError(error));
    } finally {
      window.scrollTo(0, 0);
      setLoading(false);
    }
  };

  const init = () => {
    window.scrollTo(0, 0);
    if (location.state && location.state.username && location.state.password) {
      setGlobalLoading(false);
    } else {
      goToLoginPage();
    }
  };

  useEffect(init, []);

  return {
    globalLoading, loading, goToLogin, doUpdatePassword, updateError, updateSuccess,
  };
};

export default useExpiredPasswordController;
