export const publicRoutes = {
  login: '/login',
  forgotPassword: '/olvide-mi-clave',
  expiredPassword: '/clave-expirada',
  restorePassword: '/restablecer-clave',
};

export const privateRoutes = {
  dashboard: '/',
  home: '/inicio',
  newLoanRequest: '/nueva-solicitud',
  loanRequests: '/solicitudes',
  renewers: '/renovadores',
  liquidations: '/liquidaciones',
  commission: '/comisiones',
  sales: '/ventas',
  credicuotasClub: '/club-credicuotas',
  installmentPlan: '/plan-cuotas/:hash',
  customerData: '/cargar-datos/:hash',
  documentUpload: '/cargar-documentos/:hash',
  signature: '/firma/:hash',
  final: '/final/:hash',
  manageBranches: '/gestion-sucursales',
};
