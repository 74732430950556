import { Autocomplete, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

const TypeAheadControlled = ({
  name, label, options, mandatory, helperText, defaultValue, ...props
}) => {
  const { control, formState: { errors } } = useFormContext();

  return (
    <Controller
      {...props}
      name={name}
      control={control}
      defaultValue={defaultValue || options[0]}
      render={({ field }) => (
        <Autocomplete
          {...props}
          {...field}
          disablePortal
          options={options}
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.label}
          onChange={(event, value) => field.onChange(value)}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          sx={{ minWidth: '216px' }}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                label={mandatory ? `${label} *` : label}
                error={!!errors[name]}
                helperText={errors[name]?.message}
              />
            </>
          )}
        />
      )}
    />
  );
};

TypeAheadControlled.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  defaultValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
  variant: PropTypes.string,
  helperText: PropTypes.string,
};

TypeAheadControlled.defaultProps = {
  defaultValue: '',
  mandatory: false,
  variant: 'standard',
  helperText: undefined,
};

export default TypeAheadControlled;
