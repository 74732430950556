import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocalStorage } from '@uidotdev/usehooks';
import { LOGGED_USER } from '../constants/localStorageVariablesConstants';

const AuthContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
  const [loggedUserLocalStorage, saveLoggedUserLocalStorage] = useLocalStorage(LOGGED_USER);
  const [loggedUser, setLoggedUser] = useState(loggedUserLocalStorage);

  const saveLoggedUser = (user) => {
    setLoggedUser(user);
    saveLoggedUserLocalStorage(user);
  };

  const cleanLoggedUserInfo = () => {
    setLoggedUser(undefined);
    saveLoggedUserLocalStorage(undefined);
  };

  const getLoggedUserProductList = () => loggedUser.principal.opcionesComercio.comerciosMatriz[0].comercios[0].productos;
  const getLoggedUserCommerceId = () => loggedUser.principal.infoLogin.idComercioReal;
  const isInternetChannel = () => loggedUser.principal.opcionesComercio.comerciosMatriz[0].comercios[0].canalInternet;
  const getLoggedUserHeadOfficeId = () => loggedUser.principal.opcionesComercio.comerciosMatriz[0].idCasaMatriz;
  const getLoggedUserBranchId = () => loggedUser.principal.opcionesComercio.comerciosMatriz[0].comercios[0].idSucursal;
  const getLoggedUserProvince = () => loggedUser.principal.opcionesComercio.comerciosMatriz[0].comercios[0].provincia;
  const getLoggedUserBranchCategory = () => loggedUser.principal.opcionesComercio.comerciosMatriz[0].comercios[0].categoria;
  const getLoggedUserBranchSubcategory = () => loggedUser.principal.opcionesComercio.comerciosMatriz[0].comercios[0].subCategoria;
  const getLoggedUserEmail = () => loggedUser.principal.infoLogin.mail;
  const getLoggedUserUserName = () => loggedUser.principal.username;
  const getLoggedUserBranchIsPaperLess = () => loggedUser.principal.opcionesComercio.comerciosMatriz[0].comercios[0].paperless;
  const getLoggedUserBranchHasBiometric = () => loggedUser.principal.opcionesComercio.comerciosMatriz[0].comercios[0].biometria;

  return (
    <AuthContext.Provider value={{
      loggedUser,
      saveLoggedUser,
      getLoggedUserProductList,
      getLoggedUserCommerceId,
      isInternetChannel,
      getLoggedUserHeadOfficeId,
      getLoggedUserBranchId,
      getLoggedUserProvince,
      getLoggedUserBranchCategory,
      getLoggedUserBranchSubcategory,
      getLoggedUserEmail,
      getLoggedUserUserName,
      getLoggedUserBranchIsPaperLess,
      getLoggedUserBranchHasBiometric,
      cleanLoggedUserInfo,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  user: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};
AuthProvider.defaultProps = {
  user: undefined,
};

export const useAuth = () => useContext(AuthContext);
