import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  getDocuments, getLoanRequest, getRequiredInputFields, getVehicleParameterMinAmount,
} from '../../api/workflowApiService';
import { PLLLA_DEBIT_CARD_FIELD_NAME, PTLLA_DEBIT_CARD } from '../../constants/commerceContants';
import i18n from '../../common/i18n';
import { getApiErrorCode, getApiErrorMessage } from '../../services/errorApiService';
import { ERROR_CODE_SAPP_NO_LINES } from '../../constants/apiErrorCodeConstants';
import { useToaster } from '../../contexts/ToasterContext';
import { isProductToPayVehicle } from '../../utils/loanRequestUtils';
import { getVehicleBrands, getVehicleModels, getVehiclePrice } from '../../api/vehicleApiService';

const useInstallmentPlanController = () => {
  const { showToast } = useToaster();
  const { hash } = useParams();

  const [globalLoading, setGlobalLoading] = useState(false);
  const [loanRequest, setLoanRequest] = useState();
  const [installments, setInstallments] = useState();
  const [selectedInstallment, setSelectedInstallment] = useState();
  const [brandList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [brand, setBrand] = useState();
  const [model, setModel] = useState();
  const [vehicleModelLoading, setVehicleModelLoading] = useState();
  const [maximumLoanable, setMaximumLoanable] = useState();

  let vehicleMinAmount;

  const getInstallments = () => {
    const installmentsAux = [
      {
        installments: 36,
        amount: 92375.00,
        minimumAmount: 10000.0000,
        idLinea: 1205,
        originalAmount: null,
        discount: null,
        interests: null,
        serviceCost: null,
        tax: null,
        totalFinancialCost: null,
        nominalAnnualRate: null,
        estimatedAnnualRate: null,
        firstInstallmentDiscountPercentage: 0.0000,
        firstInstallmentAmountWithDiscount: 92375.000000,
        firstInstallmentDueDate: 1727740800000,
        postponeFirstInstallment: false,
        advancePaymentAmount: 90909,
        advancePaymentPercentage: 10,
        maxDiscountAmount: 0.0000,
      },
      {
        installments: 30,
        amount: 98436.00,
        amountOriginalLine: 128018.00, // TODO: este campo en si no existe, se obtiene de installmentsWithOriginalLine el campo amount.
        minimumAmount: 10000.0000,
        idLinea: 1205,
        originalAmount: null,
        discount: null,
        interests: null,
        serviceCost: null,
        tax: null,
        totalFinancialCost: null,
        nominalAnnualRate: null,
        estimatedAnnualRate: null,
        firstInstallmentDiscountPercentage: 0.0000,
        firstInstallmentAmountWithDiscount: 98436.000000,
        firstInstallmentDueDate: 1727740800000,
        postponeFirstInstallment: false,
        advancePaymentAmount: 90909,
        advancePaymentPercentage: 10,
        maxDiscountAmount: 0.0000,
      },
      {
        installments: 24,
        amount: 106015.00,
        amountOriginalLine: 131377.00,
        minimumAmount: 10000.0000,
        idLinea: 1205,
        originalAmount: null,
        discount: null,
        interests: null,
        serviceCost: null,
        tax: null,
        totalFinancialCost: null,
        nominalAnnualRate: null,
        estimatedAnnualRate: null,
        firstInstallmentDiscountPercentage: 0.0000,
        firstInstallmentAmountWithDiscount: 106015.000000,
        firstInstallmentDueDate: 1727740800000,
        postponeFirstInstallment: false,
        advancePaymentAmount: 90909,
        advancePaymentPercentage: 10,
        maxDiscountAmount: 0.0000,
      },
      {
        installments: 18,
        amount: 119833.00,
        amountOriginalLine: 136891.00,
        minimumAmount: 10000.0000,
        idLinea: 1205,
        originalAmount: null,
        discount: null,
        interests: null,
        serviceCost: null,
        tax: null,
        totalFinancialCost: null,
        nominalAnnualRate: null,
        estimatedAnnualRate: null,
        firstInstallmentDiscountPercentage: 0.0000,
        firstInstallmentAmountWithDiscount: 119833.000000,
        firstInstallmentDueDate: 1727740800000,
        postponeFirstInstallment: false,
        advancePaymentAmount: 90909,
        advancePaymentPercentage: 10,
        maxDiscountAmount: 0.0000,
      },
      {
        installments: 15,
        amount: 130018.00,
        amountOriginalLine: 144649.00,
        minimumAmount: 10000.0000,
        idLinea: 1205,
        originalAmount: null,
        discount: null,
        interests: null,
        serviceCost: null,
        tax: null,
        totalFinancialCost: null,
        nominalAnnualRate: null,
        estimatedAnnualRate: null,
        firstInstallmentDiscountPercentage: 0.0000,
        firstInstallmentAmountWithDiscount: 130018.000000,
        firstInstallmentDueDate: 1727740800000,
        postponeFirstInstallment: false,
        advancePaymentAmount: 90909,
        advancePaymentPercentage: 10,
        maxDiscountAmount: 0.0000,
      },
      {
        installments: 12,
        amount: 146164.00,
        minimumAmount: 10000.0000,
        idLinea: 1205,
        originalAmount: null,
        discount: null,
        interests: null,
        serviceCost: null,
        tax: null,
        totalFinancialCost: null,
        nominalAnnualRate: null,
        estimatedAnnualRate: null,
        firstInstallmentDiscountPercentage: 0.0000,
        firstInstallmentAmountWithDiscount: 146164.000000,
        firstInstallmentDueDate: 1727740800000,
        postponeFirstInstallment: false,
        advancePaymentAmount: 90909,
        advancePaymentPercentage: 10,
        maxDiscountAmount: 0.0000,
      },
      {
        installments: 9,
        amount: 170415.00,
        minimumAmount: 10000.0000,
        idLinea: 1205,
        originalAmount: null,
        discount: null,
        interests: null,
        serviceCost: null,
        tax: null,
        totalFinancialCost: null,
        nominalAnnualRate: null,
        estimatedAnnualRate: null,
        firstInstallmentDiscountPercentage: 0.0000,
        firstInstallmentAmountWithDiscount: 170415.000000,
        firstInstallmentDueDate: 1727740800000,
        postponeFirstInstallment: false,
        advancePaymentAmount: 90909,
        advancePaymentPercentage: 10,
        maxDiscountAmount: 0.0000,
      },
      {
        installments: 6,
        amount: 221594.00,
        minimumAmount: 10000.0000,
        idLinea: 1205,
        originalAmount: null,
        discount: null,
        interests: null,
        serviceCost: null,
        tax: null,
        totalFinancialCost: null,
        nominalAnnualRate: null,
        estimatedAnnualRate: null,
        firstInstallmentDiscountPercentage: 0.0000,
        firstInstallmentAmountWithDiscount: 221594.000000,
        firstInstallmentDueDate: 1727740800000,
        postponeFirstInstallment: false,
        advancePaymentAmount: 90909,
        advancePaymentPercentage: 10,
        maxDiscountAmount: 0.0000,
      },
    ];

    setInstallments(installmentsAux);
  };

  const getModelsByBrand = async (brandId) => {
    try {
      setVehicleModelLoading(true);
      const { data: modelListAux } = await getVehicleModels(brandId);
      setModelList(modelListAux);
    } catch (error) {
      showToast(i18n.InstallmentPlanPage.vehicleSelection.modelListError, 'error');
    } finally {
      setVehicleModelLoading(false);
    }
  };

  const onChangeBrand = (e) => {
    const brandId = e.target.value;
    setBrand(brandId);
    setModel('none');
    setMaximumLoanable(undefined);
    getModelsByBrand(brandId);
  };

  const calculateMaximumLoanable = (vehiclePrice) => {
    let availableAmount = loanRequest.response.maxAmount;
    const vehicleMaxLoanable = (vehiclePrice * loanRequest.loanToValue) / 100;
    if (loanRequest.response.prePaidLoan) {
      availableAmount -= loanRequest.response.prePaidLoanAmount;
    }

    if (availableAmount < vehicleMaxLoanable) {
      setMaximumLoanable(availableAmount);
    } else {
      setMaximumLoanable(vehicleMaxLoanable);
    }
  };

  const getVehiclePriceByModelId = async (modelId) => {
    try {
      const res = await getVehiclePrice(modelId);
      if (res.data.list_price) {
        const vehiclePrice = (res.data.list_price * loanRequest.loanToValue) / 100;
        if (vehiclePrice < vehicleMinAmount) {
          showToast(i18n.InstallmentPlanPage.vehicleSelection.priceLowerThanMin, 'info');
        } else {
          calculateMaximumLoanable(res.data.list_price);
        }
      }
    } catch (error) {
      showToast(i18n.InstallmentPlanPage.vehicleSelection.priceError, 'error');
    }
  };

  const onChangeModel = (e) => {
    const modelId = e.target.value;
    setModel(modelId);
    getVehiclePriceByModelId(modelId);
  };

  const init = async () => {
    try {
      setGlobalLoading(true);
      const { data: lr } = await getLoanRequest(hash);

      if (!lr.response.rejected) {
        if (isProductToPayVehicle(lr)) {
          const { data: minAmountForVehicle } = await getVehicleParameterMinAmount();
          vehicleMinAmount = minAmountForVehicle;
          try {
            const { data: brandListAux } = await getVehicleBrands();
            setBrandList(brandListAux);
          } catch (error) {
            showToast(i18n.InstallmentPlanPage.vehicleSelection.brandListError, 'error');
          }
        }

        const [{ data: requiredDocuments }, { data: requiredInputFields }] = await Promise.all([getDocuments(hash), getRequiredInputFields(hash)]);

        const cardInput = requiredInputFields.find((field) => field.name === PTLLA_DEBIT_CARD);
        if (cardInput) {
          cardInput.userInputOptions.label = i18n.InstallmentPlanPage.debitCard;
          cardInput.name = PLLLA_DEBIT_CARD_FIELD_NAME;
          requiredDocuments.push(cardInput);
        }
        lr.requiredDocuments = requiredDocuments;
      }

      setLoanRequest(lr);
      // TODO: mock installments
      getInstallments();
    } catch (error) {
      if (getApiErrorCode(error) === ERROR_CODE_SAPP_NO_LINES) {
        // TODO: casuistica de pantalla cuando no tiene lineas
      } else {
        showToast(getApiErrorMessage(error), 'error');
      }
    } finally {
      setGlobalLoading(false);
    }
  };

  const doReload = () => {
    init();
  };

  useEffect(() => { init(); }, []);

  return {
    globalLoading,
    loanRequest,
    doReload,
    installments,
    selectedInstallment,
    setSelectedInstallment,
    brandList,
    modelList,
    brand,
    model,
    onChangeBrand,
    vehicleModelLoading,
    onChangeModel,
    maximumLoanable,
  };
};

export default useInstallmentPlanController;
