import { Outlet } from 'react-router';
import { Box } from '@mui/material';
import SideMenu from './SideMenu';
import { MENU_WIDTH_SIZE } from '../../../utils/menuUtils';

const DashboardLayoutComponent = () => (
  <>
    <SideMenu />
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        width: `calc(100% - ${MENU_WIDTH_SIZE})`,
        transform: `translate(${MENU_WIDTH_SIZE}, 0px)`,
        paddingBottom: '30px',
      }}
    >
      <Outlet />
    </Box>
  </>
);

export default DashboardLayoutComponent;
