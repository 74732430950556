import { logout } from '../api/authenticationApiService';
import { useAuth } from '../contexts/AuthContext';

const useLogout = () => {
  const { cleanLoggedUserInfo } = useAuth();
  const doLogout = async () => {
    try {
      await logout();
    } catch (error) {
      // nothing to do
    } finally {
      cleanLoggedUserInfo();
    }
  };

  return { doLogout };
};

export default useLogout;
