import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getLoanRequest, getQrMobileUrlBack } from '../../api/workflowApiService';
import { useToaster } from '../../contexts/ToasterContext';
import { getApiErrorCode } from '../../services/errorApiService';

const useDocumentUploadController = () => {
  const { hash } = useParams();

  const { showToast } = useToaster();
  const [globalLoading, setGlobalLoading] = useState(true);
  const [loanRequest, setLoanRequest] = useState();
  const [QR, setQR] = useState();

  const init = async () => {
    try {
      setGlobalLoading(true);
      window.scrollTo(0, 0);
      const { data: lr } = await getLoanRequest(hash);
      setLoanRequest(lr);

      // TODO: ver restriccion link de pago

      const { data: qrURL } = await getQrMobileUrlBack(hash);
      setQR(qrURL);
    } catch (error) {
      showToast(getApiErrorCode(error), 'error');
    } finally {
      setGlobalLoading(false);
    }
  };

  useEffect(() => { init(); }, []);
  return {
    globalLoading,
    loanRequest,
    QR,
  };
};

export default useDocumentUploadController;
