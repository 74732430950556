import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../contexts/AuthContext';
import { publicRoutes } from '../constants/routesConstants';

const LoggedRoute = ({ children }) => {
  const { loggedUser } = useAuth();
  return loggedUser ? children : <Navigate to={publicRoutes.login} replace />;
};

LoggedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoggedRoute;
