import { createTheme } from '@mui/material/styles';

const palette = {
  primary: {
    main: '#E72064',
  },
  secondary: {
    main: '#26C6DA',
  },
  success: {
    main: '#02C66A',
  },
  error: {
    main: '#FB1531',
  },
  warning: {
    main: '#F5A623',
  },
  info: {
    main: '#3988FF',
  },
  grey: {
    main: '#424242',
    contrastText: '#fff',
  },
  text: {
    primary: '#424242',
  },
};

const Theme = createTheme({
  typography: {
    h1: {
      fontFamily: 'Open Sans,sans-serif',
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: '32px',
    },
    h2: {
      fontFamily: 'Open Sans,sans-serif',
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    list: {
      fontFamily: 'Open Sans,sans-serif',
    },
    p: {
      fontFamily: 'Open Sans,sans-serif',
    },
    h3: {
      fontFamily: 'Open Sans,sans-serif',
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    h4: {
      fontFamily: 'Open Sans,sans-serif',
      fontSize: '1.2rem',
      fontWeight: 700,
    },
    h5: {
      fontFamily: 'Open Sans,sans-serif',
      fontSize: '1.2rem',
      fontWeight: 400,
    },
    h6: {
      fontFamily: 'Open Sans,sans-serif',
      fontSize: '1rem',
      fontWeight: 400,
    },
    button: {
      fontFamily: 'Open Sans,sans-serif',
      fontWeight: 700,
      textTransform: 'none',
    },
    fontFamily: 'Open Sans,sans-serif',
  },
  palette,
  components: {
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: '700',
          textAlign: 'left',
          fontSize: '0.875rem',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: '700',
          borderRadius: '50px',
          minWidth: '252px',
          boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.75)',
          '&:hover': {
            boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.75)',
          },
          '&.MuiButton-colorGrey': {
            '&:hover': {
              backgroundColor: '#6A6A6A',
            },
          },
        },
        outlined: {
          border: '2px solid',
          '&:hover': {
            border: '2px solid',
          },
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        size: '1rem',
      },
      styleOverrides: {
        colorPrimary: {
          color: palette.primary.main,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          whiteSpace: 'normal',
          fontSize: '0.875rem',
          '&.Mui-selected': {
            backgroundColor: '#E0E0E0',
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#E0E0E0',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&$selected': {
            color: 'black',
            backgroundColor: 'light gray',
            cursor: 'default',
          },
          '&$selected:hover': {
            color: 'black',
            backgroundColor: '#d6d6d6',
            cursor: 'default',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: 'black',
            backgroundColor: '#E0E0E0',
            cursor: 'text',
            '&:hover': {
              backgroundColor: '#E0E0E0',
            },
            '& .MuiListItemText-root': {
              '& .MuiTypography-root': {
                fontWeight: 600,
              },
            },
          },
        },
      },
    },
    MuiSelect: {
      selectmenu: {
        whiteSpace: 'normal',
      },
      styleOverrides: {
        root: {
          textAlign: 'left',
          fontSize: '0.875rem',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.75)',
          webkitBoxShadow: '0px 0px 0px 0px rgba(0,0,0,0.75)',
          mozBoxShadow: '0px 0px 0px 0px rgba(0,0,0,0.75)',
          border: '1px solid #E0E0E0',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: '0.875rem',
          fontWeight: '700',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          backgroundColor: '#F5F5F5',
        },
      },
    },
    MuiAccordion: {
      root: {
        border: '1px',
      },
    },
    MuiCheckbox: {
      root: {
        '&$checked': {
          color: '#e72064',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          color: '#4A4A4A',
          '&$disabled': {
            color: '#9B9B9B',
          },
        },
      },
    },
    // texto ingresado
    MuiInputBase: {
      input: {
        '&$disabled': {
          color: '#444444',
        },
      },
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          fontSize: '0.875rem',
        },
        option: {
          fontSize: '0.875rem',
        },
      },
    },
    // fin estilos personalizados de input
    MuiTab: {
      styleOverrides: {
        root: {
          // Estilo cuando el tab NO está seleccionado
          fontWeight: 400,
          fontSize: '14px',
          '&.Mui-selected': {
            // Estilo cuando el tab está seleccionado
            color: '#424242',
            backgroundColor: '#DA00670D',
            fontWeight: '700',
            fontSize: '14px',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '14px',
        },
      },
    },
  },
  button: {
    card: {
      fontFamily: 'Poppins,sans-serif',
    },
  },
  body: {
    backgroundColor: 'white',
  },
});

export default Theme;
