import { styled, Typography } from '@mui/material';

const SectionTitle = styled(Typography)(() => ({
  textAlign: 'left',
  fontWeight: 600,
  color: '#424242',
  fontSize: '16px',
}));

export default SectionTitle;
