export const BRANCH_CASH_ID = 99;
export const WC = 'WC';

export const PTLLA_DEBIT_CARD = 'PtllaTarjetaDebito';
export const PLLLA_DEBIT_CARD_FIELD_NAME = 'DebitCard';
export const PREPAID_CARD = 'PREPAID_CARD';

export const PLEDGE_DOCS = ['RQTitularidadVehiculo', 'RQPrenda', 'RQPolizaVehiculo'];
export const NPS_PRODUCT_LIST = [2, 11, 21, 30, 31, 32, 33, 36, 40, 41, 42, 43, 44, 45];
export const NPS_ORGANIZATION = 'nps_cc_wc';

export const DISBURSEMENT_MODE_PREPAID_CARD = 'PREPAID_CARD';
