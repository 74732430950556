import { Card as VulpinoCard } from '@doit/pcnt-react-ui-library';
import { styled } from '@mui/material';

const Card = styled(VulpinoCard)(({ theme }) => ({
  margin: '20px',
  marginLeft: '68px',
  marginRight: '68px',
  paddingTop: '0px',
  paddingLeft: '8px',
  paddingRight: '8px',
  paddingBottom: '16px',
  border: '1px solid #E0E0E0',
  boxShadow: '0px 5px 15px 0px #0000001F',

  [theme.breakpoints.down('sm')]: {
    marginLeft: '16px',
    marginRight: '16px',
    paddingLeft: '4px',
    paddingRight: '4px',
    paddingBottom: '8px',
  },
}));

export default Card;
