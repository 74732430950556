import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay-ts';

const LoadingOverlayCustom = ({ children, loading, message }) => (
  <LoadingOverlay
    active={loading}
    spinner
    text={message}
    styles={{
      overlay: (base) => ({
        ...base,
        background: '#000000A6',
        zIndex: 99999999,
        height: '100vh',
        position: 'fixed',
        top: 0,
        right: 0,
      }),
      spinner: (base) => ({
        ...base,
        '& svg circle': {
          stroke: '#FFA500',
        },
      }),
      zIndex: 99999999,
    }}
  >
    {children}
  </LoadingOverlay>
);

LoadingOverlayCustom.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  message: PropTypes.string,
};

LoadingOverlayCustom.defaultProps = {
  message: '',
};

export default LoadingOverlayCustom;
