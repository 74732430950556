import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { resetPassword } from '../../api/authenticationApiService';
import { getAuthenticationError } from '../../services/errorApiService';
import useNavigation from '../useNavigation';

const useRestorePasswordController = () => {
  const [queryParams, setQueryParams] = useSearchParams();
  const { goToLoginPage } = useNavigation();

  const [globalLoading, setGlobalLoading] = useState(true);
  const [restoreError, setRestoreError] = useState();

  const restorePassword = async (hash) => {
    try {
      setGlobalLoading(true);
      await resetPassword(hash);
    } catch (error) {
      setRestoreError(getAuthenticationError(error));
    } finally {
      setGlobalLoading(false);
    }
  };

  const goToLogin = () => {
    queryParams.delete('hash');
    setQueryParams(queryParams);
    goToLoginPage();
  };

  const init = () => {
    window.scrollTo(0, 0);
    const hash = queryParams.get('hash');
    if (hash) {
      restorePassword(hash);
    } else {
      goToLoginPage();
    }
  };

  useEffect(init, []);

  return {
    globalLoading, restoreError, goToLogin,
  };
};

export default useRestorePasswordController;
