import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Alert, Button } from '@doit/pcnt-react-ui-library';
import { expiredPasswordSchemaValidation } from '../../forms/loginSchemaValidations';
import i18n from '../../common/i18n';
import useExpiredPasswordController from '../../hooks/controllers/useExpiredPasswordController';
import { LOGIN_CREDICUOTAS_LOGO } from '../../constants/imageConstants';
import LoginLayoutComponent from './components/LoginLayoutComponent';
import PasswordInputControlled from '../../components/commons/PasswordInputControlled';

const ExpiredPasswordPage = () => {
  const {
    globalLoading, loading, goToLogin, doUpdatePassword, updateError, updateSuccess,
  } = useExpiredPasswordController();
  const methods = useForm({
    resolver: yupResolver(expiredPasswordSchemaValidation),
  });

  return (
    <LoginLayoutComponent loading={globalLoading}>
      <img src={LOGIN_CREDICUOTAS_LOGO} alt="Credicuotas" style={{ marginTop: '64px', marginBottom: '28px' }} />
      {updateSuccess ? (
        <>
          <Typography variant="h1" align="left" sx={{ marginBottom: '12px', display: 'flex', alignItems: 'center' }}>
            {i18n.ExpiredPassword.title[1]}
            <CheckCircleOutlineIcon sx={{
              marginLeft: '4px',
              color: '#02c66a',
              fontSize: '2rem',
              fontWeight: 'bold',
            }}
            />
          </Typography>
          <Typography align="left" sx={{ marginBottom: '28px' }}>
            {i18n.ExpiredPassword.description[1]}
          </Typography>
          <Button variant="primary" onClick={goToLogin} fullWidth sx={{ marginTop: '32px' }}>
            {i18n.Login.loginCTA}
          </Button>
        </>
      ) : (
        <>
          <Alert severity="warning" sx={{ marginBottom: '28px', width: '-webkit-fill-available' }}>
            {i18n.ExpiredPassword.warningMessage}
          </Alert>
          <Typography variant="h1" align="left" sx={{ marginBottom: '12px' }}>
            {i18n.ExpiredPassword.title[0]}
          </Typography>
          <Typography align="left" sx={{ marginBottom: '28px' }}>
            {i18n.ExpiredPassword.description[0]}
          </Typography>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(doUpdatePassword)}>
              {updateError && (
                <Alert severity="error" sx={{ marginBottom: '28px' }}>
                  <Typography align="left" sx={{ fontSize: '14px' }}><b>{updateError.description}</b></Typography>
                </Alert>
              )}
              <PasswordInputControlled
                name="newPass"
                label={i18n.ExpiredPassword.newPassInput}
                variant="outlined"
                fullWidth
                mandatory
                sx={{ marginBottom: '12px' }}
              />
              <PasswordInputControlled
                name="repeatNewPass"
                label={i18n.ExpiredPassword.newPassRepeatInput}
                variant="outlined"
                fullWidth
                mandatory
              />
              <Button loading={loading} variant="primary" type="submit" fullWidth sx={{ marginTop: '32px' }}>
                {i18n.ExpiredPassword.updateCTA}
              </Button>
              <Button loading={loading} variant="secondary" onClick={goToLogin} fullWidth sx={{ marginTop: '16px' }}>
                {i18n.ExpiredPassword.cancelCTA}
              </Button>
            </form>
          </FormProvider>
        </>
      )}
    </LoginLayoutComponent>
  );
};

export default ExpiredPasswordPage;
