import PropTypes from 'prop-types';
import { Navigate } from 'react-router';
import { privateRoutes } from '../constants/routesConstants';
import { useAuth } from '../contexts/AuthContext';

const RestrictedRoute = ({ condition, element, redirectTo }) => {
  const { loggedUser } = useAuth();
  return condition(loggedUser) ? element : <Navigate to={redirectTo} replace />;
};

RestrictedRoute.propTypes = {
  condition: PropTypes.func.isRequired,
  element: PropTypes.node.isRequired,
  redirectTo: PropTypes.string,
};

RestrictedRoute.defaultProps = {
  redirectTo: privateRoutes.home,
};

export default RestrictedRoute;
