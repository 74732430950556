import Payment from 'payment';

export function clearValueOnlyNumbers(value = '') {
  return value.replace(/\D+/g, '');
}

function clearValueOnlyLetters(value) {
  return value.replace(/[^a-zA-Z ]/g, '');
}

export function formatCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearValueOnlyNumbers(value);
  let nextValue;

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10,
      )} ${clearValue.slice(10, 15)}`;
      break;
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10,
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8,
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatName(value) {
  return clearValueOnlyLetters(value);
}

export function formatExpirationDate(value) {
  const clearValue = clearValueOnlyNumbers(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearValueOnlyNumbers(value);
  let maxLength = 3;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === 'amex' ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export const MASK = {
  mastercard: '9999 9999 9999 9999',
  visa: '9999 9999 9999 9999',
  amex: '9999 999999 99999',
  diners: '9999 9999 9999 99',
  discover: '9999 9999 9999 9999',
  unknown: '9999 9999 9999 9999',
  maestro3: '9999999999999999?9?9?9?',
};

export const resolveMaskByType = (type) => MASK[type];

export const resolveDebitCardType = (creditCardNumber) => {
  let result = 'unknown';
  if (/^5[1-5]/.test(creditCardNumber)) {
    result = 'mastercard';
  } else if (/^4/.test(creditCardNumber)) {
    result = 'visa';
  } else if (/^(5010|5012|5020|5038|5887|5895|5896|5894|5892|6010|6304|6759|6761|6763)/.test(creditCardNumber)) {
    result = 'maestro3';
  } else if (/^3[47]/.test(creditCardNumber)) {
    result = 'amex';
  } else if (/3(?:0[0-5]|[68][0-9])[0-9]{11}/.test(creditCardNumber)) {
    result = 'diners';
  } else if (/6(?:011|5[0-9]{2})[0-9]{12}/.test(creditCardNumber)) {
    result = 'discover';
  }
  return result;
};

export const resolveMaskByNumber = (number) => {
  const type = resolveDebitCardType(number);
  return resolveMaskByType(type);
};
