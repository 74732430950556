import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../contexts/AuthContext';
import { privateRoutes } from '../constants/routesConstants';

const PublicRoute = ({ children }) => {
  const { loggedUser } = useAuth();
  return loggedUser ? <Navigate to={privateRoutes.dashboard} replace /> : children;
};

PublicRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PublicRoute;
