export const hasDiscount = (installment) => installment.amountOriginalLine > 0;

export const hasPromoCADECOM = (installment) => {
  const idLineasList = [1199, 1205, 848, 865, 1152, 970, 946, 1211, 1220, 872, 945];
  return (installment.installments === 24 || installment.installments === 30) && idLineasList.includes(installment.idLinea);
};

export const getDiscountPercentage = (installment) =>
  Math.floor(((installment.amountOriginalLine - installment.amount) / installment.amountOriginalLine) * 100);

export const getDiscountAmount = (installment) => installment.amountOriginalLine - installment.amount;
