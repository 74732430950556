/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  getLoanRequest, getUserInputFields, resolveConditionalComboData, sendVerificationCode,
  setCustomerData,
  verifyCode,
} from '../../api/workflowApiService';
import { defaultComboBoxOption } from '../../forms/customerDataSchemaValidations';
import { useToaster } from '../../contexts/ToasterContext';
import { getApiErrorCode, getApiErrorMessage } from '../../services/errorApiService';
import { ERROR_SENDING_EMAIL } from '../../constants/apiErrorCodeConstants';
import i18n from '../../common/i18n';
import { DISBURSEMENT_MODE_PREPAID_CARD } from '../../constants/commerceContants';

const useCustomerDataController = () => {
  const { hash } = useParams();

  const { showToast } = useToaster();
  const [globalLoading, setGlobalLoading] = useState(true);
  const [loanRequest, setLoanRequest] = useState();
  const [inputGroups, setInputGroups] = useState();

  const [mustLoadDebitCardInfo, setMustLoadDebitCardInfo] = useState();
  const [hasDebitCardValid, setHasDebitCardValid] = useState();
  const [userFilled, setUserFilled] = useState({});
  const [ctaLoading, setCtaLoading] = useState(false);

  const userFilledAux = {};

  const resolveToShowConditional = (input) =>
    !input.userInputOptions.conditionalUserInputName
    || (input.userInputOptions.conditionalUserInputName && input.userInputOptions.isConditionalToShow);

  const resolveToShowUserInputIfAlreadyHasValue = (input) =>
    !(input.userInputOptions.mustHideIfAlreadyHasValue && input.userInputOptions.defaultValue);

  const onSelectOption = async (input, inputGroup, value, groupIndex, methods) => {
    input.userInputOptions.comboData.forEach((option) => {
      if (option.id === value) {
        userFilledAux[input.name] = option;
      }
    });
    setUserFilled(userFilledAux);
    await resolveIfOtherFieldsDependsOnMe(input, inputGroup, methods);
    const inputGroupsAux = [...inputGroups];
    inputGroupsAux[groupIndex] = inputGroup;
    setInputGroups(inputGroupsAux);
  };

  const getComboDataSelectedAndSetIfSingleOption = (input) => {
    let comboSelected;

    input.userInputOptions.comboData.forEach((option) => {
      if (option.selected) {
        comboSelected = option;
      }
    });

    if (input.name === 'PtllaFormasDePago' && !comboSelected && input.userInputOptions.comboData.length === 1 && input.userInputOptions.required) {
      [comboSelected] = input.userInputOptions.comboData;

      userFilledAux[input.name] = comboSelected;
      setUserFilled(userFilledAux);
      inputGroups.forEach((group) => {
        if (input.group === group.name) {
          resolveIfOtherFieldsDependsOnMe(input, group);
        }
      });
    }
    return comboSelected;
  };

  const resolveIfOtherFieldsDependsOnMe = async (input, group, methods) => {
    // si hay campos que dependen de mi los resuelvo
    if (input.userInputOptions.inputsThatDependsOnMe && input.userInputOptions.inputsThatDependsOnMe.length > 0) {
      // recorro todos los campos que están en el mismo grupo
      for (const variable of group.variables) {
        // si existe una variable que está dentro de los campos que dependen de mi
        if (input.userInputOptions.inputsThatDependsOnMe.indexOf(variable.name) > -1) {
          // reseteo el valor del campo condicional
          userFilledAux[variable.name] = undefined;
          if (variable.userInputOptions.type === 'COMBO') {
            methods?.resetField(variable.name, { defaultValue: defaultComboBoxOption.id });
          } else if (variable.userInputOptions.type === 'TYPEAHEAD') {
            methods?.resetField(variable.name, { defaultValue: defaultComboBoxOption });
          }
          // el valor a usar depende de si viene por default o si se selecciona en la pantalla
          let valueToUse = input.userInputOptions.defaultValue;
          if (!valueToUse || (userFilledAux[input.name] && userFilledAux[input.name] !== valueToUse)) {
            valueToUse = userFilledAux[input.name] ? userFilledAux[input.name].id : undefined;
          }

          // si mi valor no es undefined (el del combo que acabo de modificar)
          // y (el campo condicional no depende de un valor o tiene un valor que lo condiciona y coincide con  mi valor),
          // lo muestro
          if ((userFilledAux[input.name] || input.userInputOptions.defaultValue)
            && (
              !variable.userInputOptions.conditionalUserInputValue
              || (variable.userInputOptions.conditionalUserInputValue
                && variable.userInputOptions.conditionalUserInputValue === valueToUse))) {
            variable.userInputOptions.isConditionalToShow = true;
          } else {
            variable.userInputOptions.isConditionalToShow = false;
          }

          if (input.name === 'PtllaFormasDePago') {
            setMustLoadDebitCardInfo(false);
          }

          // si tengo que mostrar el campo condicional y es un combo, voy al backend a resolver la lista de opciones
          if (variable.userInputOptions.isConditionalToShow) {
            if (variable.userInputOptions.type === 'COMBO'
              || variable.userInputOptions.type === 'TYPEAHEAD'
              || variable.userInputOptions.type === 'RADIO') {
              try {
                const { data } = await resolveConditionalComboData(hash, variable.name, valueToUse);
                variable.userInputOptions.comboData = data;
                let comboItemSelected = getComboDataSelectedAndSetIfSingleOption(variable);
                if (!comboItemSelected && variable.userInputOptions.comboData.length === 1) {
                  [comboItemSelected] = variable.userInputOptions.comboData;
                  userFilledAux[variable.name] = comboItemSelected;
                  setUserFilled(userFilledAux);
                  resolveIfOtherFieldsDependsOnMe(variable, group);
                } else {
                  const [val] = data;
                  userFilledAux[variable.name] = val;
                  setUserFilled(userFilledAux);
                }
              } catch (error) {
                showToast(getApiErrorMessage(error), 'error');
              }
            } else if (variable.userInputOptions.type === 'TEXT') {
              // Resuelvo si se le aplica un valor automaticamente que proviene de la opcion seleccionada del combo que lo condiciona
              // para simplificar y como solo tengo este caso de uso lo hago solo con TEXT
              if (userFilledAux[input.name].descripcion === 'CAPITAL FEDERAL') {
                // fix horrible. El tema es que en sapp el codigo postal de Cpaital Federal esta en 1000
                // en paralelo se esta haciendo un fix en el SP de sapp para que cuando es Capital no devuelva el cod postal 1000
                return;
              }
              userFilledAux[variable.name] = userFilledAux[input.name].valueToAutoCompleteConditiningInput;
            } else if (variable.userInputOptions.type === 'CARD') {
              if (variable.userInputOptions.defaultValue) {
                if (variable.userInputOptions.defaultValue !== 'INVALID_CARD') {
                  setHasDebitCardValid(true);
                }
              } else {
                setMustLoadDebitCardInfo(true);
              }
            }
          }
        }
      }
    }
    setUserFilled(userFilledAux);
  };

  const sendValidationCode = async (input, methods) => {
    try {
      const value = methods.watch(input.name);
      await sendVerificationCode(hash, input.name, value);
      return true;
    } catch (error) {
      if (getApiErrorMessage(error) === ERROR_SENDING_EMAIL) {
        showToast(`${i18n.CustomerData.sendCodeError}. ${i18n.CustomerData.reviewInputEmail}`, 'error');
      } else {
        showToast(`${i18n.CustomerData.sendCodeError}. ${getApiErrorMessage(error)}`, 'error');
      }
      return false;
    }
  };

  const verificateCode = async (code, input, methods) => {
    try {
      if (code && code.length === 4) {
        const value = methods.watch(input.name);
        const { data: { result } } = await verifyCode(hash, input.name, code, value);
        methods.setValue(`${input.name}VerificationCode`, result);
      }
    } catch (error) {
      showToast(`${i18n.CustomerData.verificationCodeError}. ${getApiErrorMessage(error)}`, 'error');
    }
  };

  const doSubmit = async (data) => {
    try {
      setCtaLoading(true);
      // valido que si el desembolso es tarjeta prepaga, ingrese un mail valido.
      if (userFilled.PtllaModoDesembolso && userFilled.PtllaModoDesembolso.id === DISBURSEMENT_MODE_PREPAID_CARD) {
        if (data.PtllaMail === undefined || data.PtllaMail === null) {
          showToast(i18n.CustomerData.disbursementPrePaidCardMailRequired, 'info');
        }
        if (data.PtllaCodPostal === undefined || data.PtllaCodPostal === null) {
          showToast(i18n.CustomerData.disbursementPrePaidCardZipCodeRequired, 'info');
        }
      }

      const dataAux = { ...data };
      Object.entries(dataAux).forEach(([key, value]) => {
        if (typeof value === 'object') {
          dataAux[key] = value.id;
        }
      });

      const res = await setCustomerData(hash, dataAux);
      console.log(res);
      // TODO: continuar al siguiente step
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setCtaLoading(false);
    }
  };

  const init = async () => {
    try {
      setGlobalLoading(true);
      window.scrollTo(0, 0);
      const { data: lr } = await getLoanRequest(hash);
      setLoanRequest(lr);

      // TODO: tema link de pago generado, va a seguir tal cual hoy en dia?
      // if ($scope.loanRequest.productToPay && $scope.loanRequest.productToPay.showcaseType &&
      //   $scope.loanRequest.productToPay.product.productAttributes.productAmount){
      //       toaster.pop('info', "La solicitud tiene un link de pago generado.");
      //       $location.path('/loanRequests');
      // }

      const { data: { groups } } = await getUserInputFields(hash);
      for (const group of groups) {
        for (const variable of group.variables) {
          if (variable.userInputOptions.type === 'COMBO' || variable.userInputOptions.type === 'TYPEAHEAD') {
            await resolveIfOtherFieldsDependsOnMe(variable, group);
          } else if (variable.userInputOptions.type === 'CARD') {
            // verifica si ya fue validada alguna tarjeta para no tratar el formulario CARD como obligatorio para continuar
            if (variable.name === 'PtllaTarjetaDebito' && variable.userInputOptions.defaultValue) {
              setMustLoadDebitCardInfo(false);

              if (variable.userInputOptions.defaultValue !== 'INVALID_CARD') {
                setHasDebitCardValid(true);
              }
            }
          }
        }
      }
      setInputGroups(groups);
    } catch (error) {
      showToast(getApiErrorCode(error), 'error');
    } finally {
      setGlobalLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return {
    globalLoading,
    loanRequest,
    inputGroups,
    resolveToShowConditional,
    resolveToShowUserInputIfAlreadyHasValue,
    onSelectOption,
    sendValidationCode,
    verificateCode,
    mustLoadDebitCardInfo,
    hasDebitCardValid,
    userFilled,
    doSubmit,
    ctaLoading,
  };
};

export default useCustomerDataController;
