import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { publicRoutes, privateRoutes } from '../constants/routesConstants';

const useNavigation = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const goToLoginPage = () => {
    navigate({
      pathname: publicRoutes.login,
      search: queryParams ? queryParams.toString() : '',
    });
  };

  const goHome = () => {
    navigate({ pathname: privateRoutes.home });
  };

  const navigation = (path, state) => {
    navigate({
      pathname: path,
      search: queryParams ? queryParams.toString() : '',
    }, { state });
  };

  const redirectLoanRequest = (loanRequest) => {
    if (!loanRequest.installmentAmountSelected) {
      navigation(`/plan-cuotas/${loanRequest.hashKey}`);
    } else if (!loanRequest.customerDataCompleted) {
      navigation(`/cargar-datos/${loanRequest.hashKey}`);
    } else if (!loanRequest.hasSigned && loanRequest.isPaperless) {
      navigation(`/TBD/${loanRequest.hashKey}`);
    } else if (!loanRequest.documentsCompleted) {
      navigation(`/TBD/${loanRequest.hashKey}`);
    } else {
      navigation(`/TBD/${loanRequest.hashKey}`);
    }
  };

  return {
    goToLoginPage, goHome, navigation, redirectLoanRequest,
  };
};

export default useNavigation;
