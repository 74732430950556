import PropTypes from 'prop-types';
import {
  FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { defaultComboBoxOption } from '../../../forms/customerDataSchemaValidations';

const CustomerDataComboBox = ({
  input, inputGroup, index, methods, onSelectOption,
}) => (
  <Grid item xs="auto">
    <FormControl fullWidth error={!!(methods.formState?.errors && methods.formState?.errors[input.name])}>
      <InputLabel id={`${input.name}-select-label`}>
        {input.userInputOptions.required ? `${input.userInputOptions.label} *` : input.userInputOptions.label}
      </InputLabel>
      <Controller
        name={input.name}
        control={methods.control}
        defaultValue={input.userInputOptions.defaultValue || 'none'}
        render={({ field }) => (
          <Select
            {...field}
            labelId={`${input.name}-select-label`}
            id={`${input.name}-select`}
            value={field.value || input.userInputOptions.defaultValue || 'none'}
            label={input.userInputOptions.required ? `${input.userInputOptions.label} *` : input.userInputOptions.label}
            sx={{ minWidth: '216px' }}
            onChange={(e) => {
              field.onChange(e.target.value);
              onSelectOption(input, inputGroup, e.target.value, index, methods);
            }}
          >
            <MenuItem value={defaultComboBoxOption.id}>{defaultComboBoxOption.label}</MenuItem>
            {input.userInputOptions.comboData.map((option) => (
              <MenuItem value={option.id} key={option.id}>{option.descripcion}</MenuItem>
            ))}
          </Select>
        )}
      />
      {methods.formState.errors && methods.formState.errors[input.name] && (
        <FormHelperText>{methods.formState.errors[input.name].message}</FormHelperText>
      )}
    </FormControl>
  </Grid>
);

CustomerDataComboBox.propTypes = {
  input: PropTypes.shape().isRequired,
  inputGroup: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  methods: PropTypes.shape().isRequired,
  onSelectOption: PropTypes.func.isRequired,
};

export default CustomerDataComboBox;
