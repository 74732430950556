const fillRegexp = /\$\{([^\\}]*(?:\\.[^\\}]*)*)\}/g;

export const fillMessageWith = (message, ...args) => {
  const values = args.reduce((acc, v, i) => {
    acc[i.toString()] = v;
    return acc;
  }, {});
  return message.replace(fillRegexp, (exp, prop) => (values[prop] !== undefined ? values[prop] : ''));
};

export const isMobileOperatingSystem = () => {
  const userAgent = window.navigator.userAgent || window.navigator.vendor || window.window.opera;
  if (/windows phone/i.test(userAgent)) {
    return true;
  }
  if (/android/i.test(userAgent)) {
    return true;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }
  return false;
};

export const dataURLtoBlob = (dataUrl) => {
  const byteString = atob(dataUrl.split(',')[1]);
  const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};
