import i18n from '../common/i18n';

export const getAuthenticationError = (error) => {
  const errorCode = error?.response?.data?.errorCode || 'GENERIC_ERROR';
  return { code: errorCode, description: i18n.Login.errors[errorCode] };
};

export const getApiErrorMessage = (error) => {
  const errorMessage = error?.response?.data?.cause || i18n.Login.errors.GENERIC_ERROR;
  return errorMessage;
};

export const getApiErrorCode = (error) => error?.response?.data?.errorCode;
