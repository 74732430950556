import useManageBranchesController from '../../hooks/controllers/useManageBranchesController';
import CreateBranchForm from './components/CreateBranchForm';
import ListBranch from './components/ListBranch';

const ManageBranchesPage = () => {
  const {
    methods,
    sellerList,
    createUser,
    addUserLoading,
  } = useManageBranchesController();

  return (
    <>
      <CreateBranchForm methods={methods} onSubmit={createUser} loading={addUserLoading} />
      <ListBranch sellerList={sellerList} />
    </>
  );
};

export default ManageBranchesPage;
