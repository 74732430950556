import { TextInput } from '@doit/pcnt-react-ui-library';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

const TextInputControlled = ({
  name, label, mandatory, helperText, defaultValue, onBlur, onChange, ...props
}) => {
  const { control, formState: { errors } } = useFormContext();

  return (
    <Controller
      {...props}
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextInput
          {...props}
          {...field}
          helperText={errors[name]?.message || helperText}
          error={!!errors[name]}
          label={mandatory ? `${label}*` : label}
          refs={field}
          value={field.value || ''}
          inputRef={field.ref}
          onBlur={onBlur}
          onChange={(e) => {
            field.onChange(e);
            if (onChange) onChange(e);
          }}
        />
      )}
    />
  );
};

TextInputControlled.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
  variant: PropTypes.string,
  helperText: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

TextInputControlled.defaultProps = {
  defaultValue: '',
  mandatory: false,
  variant: 'standard',
  helperText: undefined,
  onBlur: undefined,
  onChange: undefined,
};

export default TextInputControlled;
