import * as yup from 'yup';
import i18n from '../common/i18n';

export const loginSchemaValidation = yup.object().shape({
  user: yup.string().required(i18n.schemaValidation.user.required),
  pass: yup.string().required(i18n.schemaValidation.pass.required),
});

export const forgotPasswordSchemaValidation = yup.object().shape({
  user: yup.string().required(i18n.schemaValidation.user.required),
});

export const expiredPasswordSchemaValidation = yup.object().shape({
  newPass: yup.string()
    .required(i18n.schemaValidation.newPass.required)
    .min(6, i18n.schemaValidation.newPass.minLength)
    .matches(/^[A-Za-z0-9]+$/, i18n.schemaValidation.newPass.alphanumeric),
  repeatNewPass: yup.string()
    .required(i18n.schemaValidation.repeatNewPass.required)
    .oneOf([yup.ref('newPass'), null], i18n.schemaValidation.repeatNewPass.match),
});
