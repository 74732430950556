import {
  Box, Grid, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import {
  Button, CardActions, CardContent, Signature,
} from '@doit/pcnt-react-ui-library';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Card from '../../components/commons/Card';
import Loading from '../../components/commons/Loading';
import useSignatureController from '../../hooks/controllers/useSignatureController';
import StepHeader from './components/StepHeader';
import i18n from '../../common/i18n';
import TextInputControlled from '../../components/commons/TextInputControlled';
import { sendAgreementSchemaValidation } from '../../forms/signatureSchemaValidations';

const SignaturePage = () => {
  const {
    globalLoading,
    loanRequest,
    sendAgreement,
    sendAgreementLoading,
    sigPadRef,
    signature,
    signLoading,
    doSignAgreement,
  } = useSignatureController();

  const methods = useForm({
    resolver: yupResolver(sendAgreementSchemaValidation),
  });

  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  if (globalLoading) {
    return <Loading />;
  }

  return (
    <>
      <Card>
        <CardContent>
          <StepHeader loanRequest={loanRequest} />
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Grid container sx={{ marginBottom: '24px' }}>
            <Grid item xs={12} md={6}>
              <Typography align="left" sx={{ fontSize: '20px', fontWeight: 600 }}>{i18n.SignaturePage.title}</Typography>
              <Typography align="left" sx={{ fontSize: '14px', marginTop: '4px', marginBottom: '24px' }}>{i18n.SignaturePage.description}</Typography>
              <Typography align="left" sx={{ fontSize: '14px', fontWeight: 600 }}>{i18n.SignaturePage.sendAgreementTitle}</Typography>
              <Typography align="left" sx={{ fontSize: '14px', marginTop: '4px', marginBottom: '16px' }}>
                {i18n.SignaturePage.sendAgreementDescription}
              </Typography>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(sendAgreement)} style={{ textAlign: 'left' }}>
                  <TextInputControlled
                    name="cellphone"
                    label={i18n.SignaturePage.cellphoneInput}
                    helperText={i18n.SignaturePage.cellphoneInputHint}
                    variant="outlined"
                    mandatory
                  />
                  <Box sx={{ marginTop: '24px', textAlign: 'left' }}>
                    <Button
                      type="submit"
                      variant="tertiary"
                      sx={{
                        padding: '0px', display: 'flex', justifyContent: 'flex-start', marginBottom: '24px',
                      }}
                      loading={sendAgreementLoading}
                    >
                      {i18n.SignaturePage.sendAgreementCTA}
                      <img src="/images/send-icon.svg" alt="icon" style={{ marginLeft: '8px' }} />
                    </Button>
                  </Box>
                </form>
              </FormProvider>
            </Grid>
            <Grid item xs={12} md={6} className="sign-pad">
              <Signature
                clearLabel={i18n.SignaturePage.repeatSignature}
                sigPadRef={sigPadRef}
                width="auto"
                placeholder={isMobile ? i18n.SignaturePage.signPlaceholder : undefined}
              />
            </Grid>
          </Grid>
          <CardActions>
            <Button
              type="submit"
              color="grey"
              disabled={signature === undefined}
              loading={signLoading}
              onClick={doSignAgreement}
            >
              {i18n.SignaturePage.signCTA}
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </>
  );
};

export default SignaturePage;
