import parse from 'html-react-parser';
import {
  Box, CircularProgress, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Tooltip, Typography,
} from '@mui/material';
import {
  Alert, Button, CardContent, Divider,
} from '@doit/pcnt-react-ui-library';
import { HelpOutline } from '@mui/icons-material';
import CachedIcon from '@mui/icons-material/Cached';
import Card from '../../components/commons/Card';
import Loading from '../../components/commons/Loading';
import useInstallmentPlanController from '../../hooks/controllers/useInstallmentPlanController';
import i18n from '../../common/i18n';
import { fillMessageWith } from '../../utils/functionsUtils';
import StepHeader from './components/StepHeader';
import { getAvailableAmount, isProductToPayVehicle } from '../../utils/loanRequestUtils';
import { moneyFormatter } from '../../utils/formatterUtils';
import { REQUIRED_DOCUMENTS } from '../../constants/imageConstants';
import InstallmentCard from './components/InstallmentCard';

const InstallmentPlanPage = () => {
  const {
    globalLoading,
    loanRequest,
    doReload,
    installments,
    selectedInstallment,
    setSelectedInstallment,
    brandList,
    modelList,
    brand,
    model,
    onChangeBrand,
    vehicleModelLoading,
    onChangeModel,
    maximumLoanable,
  } = useInstallmentPlanController();

  if (globalLoading) {
    return <Loading />;
  }

  /* **************** RECHAZADO **************** */
  // TODO: Casuistica de pantalla cuando se rechaza
  if (loanRequest && loanRequest.response.rejected) {
    return (
      <>
        <Card>
          <CardContent>
            <Alert severity="error">
              Rechazo
            </Alert>
          </CardContent>
        </Card>
      </>
    );
  }

  if (loanRequest) {
    return (
      <>
        <Card>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography align="left" sx={{ fontSize: '24px', fontWeight: 700 }}>
                {parse(fillMessageWith(i18n.InstallmentPlanPage.title, moneyFormatter(getAvailableAmount(loanRequest))))}
              </Typography>
              <Button variant="tertiary" color="grey" endIcon={<CachedIcon />} onClick={doReload}>
                {i18n.InstallmentPlanPage.ctaReload}
              </Button>
            </Box>
          </CardContent>
          <Divider sx={{ margin: '8px 16px' }} />
          <CardContent>
            <StepHeader loanRequest={loanRequest} />
            <Alert severity="info" title={i18n.InstallmentPlanPage.requiredDocumentation} sx={{ marginTop: '32px', width: 'fit-content' }}>
              <Grid container columnSpacing={2}>
                {loanRequest.requiredDocuments.map((doc) => (
                  <Grid item xs="auto" display="flex" alignItems="center" key={doc.name}>
                    <img src={REQUIRED_DOCUMENTS[doc.name]} alt={doc.userInputOptions.label} />
                    <Typography marginLeft="4px" sx={{ fontSize: '0.875rem' }}>
                      {doc.userInputOptions.label}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Alert>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            {isProductToPayVehicle(loanRequest) && (
              <>
                <Typography align="left" sx={{ fontSize: '20px', fontWeight: 600 }}>{i18n.InstallmentPlanPage.vehicleSelection.title}</Typography>
                <Typography align="left" sx={{ fontSize: '14px', marginTop: '4px', marginBottom: '32px' }}>
                  {i18n.InstallmentPlanPage.vehicleSelection.description}
                </Typography>
                <Grid container columnGap="24px" rowGap="20px">
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel id="brand-select-label">{i18n.InstallmentPlanPage.vehicleSelection.brand}</InputLabel>
                      <Select
                        labelId="brand-select-label"
                        id="brand-select"
                        value={brand || 'none'}
                        label={i18n.InstallmentPlanPage.vehicleSelection.brand}
                        onChange={onChangeBrand}
                      >
                        <MenuItem value="none">{i18n.InstallmentPlanPage.vehicleSelection.brandEmptyOption}</MenuItem>
                        {brandList.map((brandAux) => (
                          <MenuItem value={brandAux.id} key={brandAux.id}>{brandAux.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel id="model-select-label">{i18n.InstallmentPlanPage.vehicleSelection.model}</InputLabel>
                      <Select
                        labelId="model-select-label"
                        id="model-select"
                        value={model || 'none'}
                        label={i18n.InstallmentPlanPage.vehicleSelection.model}
                        onChange={onChangeModel}
                        startAdornment={
                          vehicleModelLoading && (
                            <InputAdornment position="start">
                              <CircularProgress size={20} />
                            </InputAdornment>
                          )
                        }
                      >
                        <MenuItem value="none">{i18n.InstallmentPlanPage.vehicleSelection.modelEmptyOption}</MenuItem>
                        {modelList?.map((modelAux) => (
                          <MenuItem value={modelAux.codia} key={modelAux.codia}>{modelAux.description}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {maximumLoanable && (
                  <>
                    <Typography
                      align="left"
                      sx={{
                        fontSize: '16px', fontWeight: 600, marginTop: '41px', display: 'flex',
                      }}
                    >
                      {fillMessageWith(i18n.InstallmentPlanPage.vehicleSelection.maxAmount, moneyFormatter(maximumLoanable))}
                      <Tooltip title={i18n.InstallmentPlanPage.vehicleSelection.maxAmountTooltip}>
                        <HelpOutline sx={{ marginLeft: '10px', color: '#424242' }} />
                      </Tooltip>
                    </Typography>
                    <Typography align="left" sx={{ fontSize: '14px', marginTop: '8px' }}>
                      {i18n.InstallmentPlanPage.vehicleSelection.maxAmountHint}
                    </Typography>
                  </>
                )}
                <Divider sx={{ marginTop: '32px', marginBottom: '32px' }} />
              </>
            )}

            <Typography sx={{
              textAlign: 'left', fontSize: '16px', fontWeight: 600, marginBottom: '24px',
            }}
            >
              {i18n.InstallmentPlanPage.selectInstallmentPlan}
            </Typography>
            <Grid container columnGap="12px" rowGap="20px">
              {installments?.map((installment) => (
                <Grid item xs="auto" key={installment.installments}>
                  <InstallmentCard
                    installment={installment}
                    selected={installment.installments === selectedInstallment?.installments}
                    onSelect={setSelectedInstallment}
                  />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  }

  return <></>;
};

export default InstallmentPlanPage;
