// import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { Grid, TextField, Typography } from '@mui/material';
import { Alert, Button, CardActions } from '@doit/pcnt-react-ui-library';
import i18n from '../../../common/i18n';
import { hasAdvancePayment } from '../../../utils/loanRequestUtils';
import useCustomerDataDebitCardInputController from '../../../hooks/controllers/useCustomerDataDebitCardInputController';
import { resolveMaskByNumber } from '../../../utils/cardUtils';
import { fillMessageWith } from '../../../utils/functionsUtils';
import { moneyFormatter } from '../../../utils/formatterUtils';

const CustomerDataDebitCardInput = ({
  input, loanRequest, mustLoadDebitCardInfo, hasDebitCardValid,
}) => {
  const {
    debitCardMask,
    cardNumber,
    cardHolderName,
    expiryDate,
    securityCode,
    setDebitCardMask,
    setCardNumber,
    setCardHolderName,
    setExpiryDate,
    setSecurityCode,
    validateCard,
    areAllFieldsCompletedAndValid,
    loading,
    validateDebitCardMessage,
    validateDebitCardResultSuccess,
  } = useCustomerDataDebitCardInputController(loanRequest);

  return (
    <>
      {/* TARJETA YA VALIDADA CORRECTAMENTE */}
      {!mustLoadDebitCardInfo && hasDebitCardValid && (
        <Grid item xs="auto">
          <Alert severity="success" sx={{ width: 'fit-content', textAlign: 'left' }}>
            {fillMessageWith(i18n.CustomerData.debitCardAlreadyValidated, input.userInputOptions.defaultValue)}
          </Alert>
        </Grid>
      )}
      {/* TARJETA YA CARGADA PERO INVALIDA */}
      {!mustLoadDebitCardInfo && !hasDebitCardValid && (
        <Grid item xs="auto">
          <Alert severity="info" sx={{ width: 'fit-content', textAlign: 'left' }}>
            {i18n.CustomerData.debitCardAlreadyLoadButInvalid}
          </Alert>
        </Grid>
      )}
      {/* FORMULARIO DE CARGA DE TARJETA */}
      {mustLoadDebitCardInfo && (
        <>
          <Grid container columnGap="24px" rowGap="24px">
            {hasAdvancePayment(loanRequest) && (
              <Grid item xs={12} md={6}>
                <Alert severity="info">
                  <Typography align="left" sx={{ fontSize: '1rem', fontWeight: 700 }}>
                    {i18n.CustomerData.debitCardDownPaymentInfo[0]}
                  </Typography>
                  <Typography align="left" sx={{ marginTop: '4px', fontSize: '0.875rem' }}>
                    {fillMessageWith(i18n.CustomerData.debitCardDownPaymentInfo[1], moneyFormatter(loanRequest.advancePaymentAmount))}
                  </Typography>
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography align="left" sx={{ fontSize: '1rem' }}>{i18n.CustomerData.debitCardInputTitle}</Typography>
            </Grid>
            <Grid item xs="auto">
              <InputMask
                mask={debitCardMask}
                maskChar=""
                value={cardNumber}
                onChange={(e) => { setDebitCardMask(resolveMaskByNumber(e.target.value)); setCardNumber(e.target.value); }}
                disabled={validateDebitCardResultSuccess}
              >
                {(inputProps) => (
                  <TextField
                    {...inputProps}
                    label={i18n.CustomerData.dcNumber}
                    variant="outlined"
                    fullWidth
                    disabled={validateDebitCardResultSuccess}
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs="auto">
              <TextField
                value={cardHolderName}
                onChange={(e) => setCardHolderName(e.target.value)}
                label={i18n.CustomerData.dcName}
                helperText={i18n.CustomerData.dcNameHint}
                variant="outlined"
                disabled={validateDebitCardResultSuccess}
                fullWidth
              />
            </Grid>
            <Grid item xs="auto">
              <InputMask
                mask="99/99"
                maskChar=""
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
                disabled={validateDebitCardResultSuccess}
              >
                {(inputProps) => (
                  <TextField
                    {...inputProps}
                    label={i18n.CustomerData.dcExpiration}
                    helperText={i18n.CustomerData.dcExpirationHint}
                    variant="outlined"
                    fullWidth
                    disabled={validateDebitCardResultSuccess}
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs="auto">
              <InputMask
                mask="9999"
                value={securityCode}
                onChange={(e) => setSecurityCode(e.target.value)}
                maskChar=""
                disabled={validateDebitCardResultSuccess}
              >
                {(inputProps) => (
                  <TextField
                    {...inputProps}
                    label={i18n.CustomerData.dcCvv}
                    helperText={i18n.CustomerData.dcCvvHint}
                    variant="outlined"
                    fullWidth
                    disabled={validateDebitCardResultSuccess}
                  />
                )}
              </InputMask>
            </Grid>
          </Grid>
          {/* RESULTADO DE VALIDACION DE TARJETA */}
          {(validateDebitCardResultSuccess === true || validateDebitCardResultSuccess === false) && (
            <Grid container columnGap="24px" rowGap="32px" sx={{ marginTop: '24px' }}>
              <Grid item xs={12} md={6}>
                <Alert severity={validateDebitCardResultSuccess ? 'success' : 'error'}>
                  {validateDebitCardMessage}
                </Alert>
              </Grid>
            </Grid>
          )}
          <CardActions>
            <Button
              variant="secondary"
              onClick={validateCard}
              disabled={!areAllFieldsCompletedAndValid()}
              loading={loading}
            >
              {hasAdvancePayment(loanRequest) ? i18n.CustomerData.ctaValidateCardAndCollectAdvance : i18n.CustomerData.ctaValidateCard}
            </Button>
          </CardActions>
        </>
      )}
    </>
  );
};

CustomerDataDebitCardInput.propTypes = {
  input: PropTypes.shape().isRequired,
  loanRequest: PropTypes.shape().isRequired,
  mustLoadDebitCardInfo: PropTypes.bool.isRequired,
  hasDebitCardValid: PropTypes.bool.isRequired,
};

export default CustomerDataDebitCardInput;
