import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import LoadingOverlayCustom from '../components/commons/LoadingOverlayCustom';

const OverlayLoadingContext = createContext();

export const useOverlayLoading = () => useContext(OverlayLoadingContext);

export const OverlayLoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const setOverlayLoading = (loadingAux, messageAux) => {
    setMessage(messageAux);
    setLoading(loadingAux);
  };

  return (
    <OverlayLoadingContext.Provider value={{ setOverlayLoading }}>
      <LoadingOverlayCustom loading={loading} message={message}>
        {children}
      </LoadingOverlayCustom>
    </OverlayLoadingContext.Provider>
  );
};

OverlayLoadingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
