import './App.css';
import { ThemeProvider } from '@mui/material/styles';

import Theme from './assets/theme/theme';
import AppRouter from './navigation/AppRouter';
import { AuthProvider } from './contexts/AuthContext';
import { ToasterProvider } from './contexts/ToasterContext';
import { OverlayLoadingProvider } from './contexts/OverlayLoadingContext';

const App = () => (
  <div className="App">
    <ThemeProvider theme={Theme}>
      <AuthProvider>
        <OverlayLoadingProvider>
          <ToasterProvider>
            <AppRouter />
          </ToasterProvider>
        </OverlayLoadingProvider>
      </AuthProvider>
    </ThemeProvider>
  </div>
);

export default App;
