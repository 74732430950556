import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@mui/material';

const CheckboxControlled = ({
  name, label, defaultValue, ...props
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      {...props}
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormControlLabel
          control={<Checkbox {...field} checked={!!field.value} />}
          label={label}
          sx={{ textAlign: 'left' }}
          {...props}
        />
      )}
    />
  );
};

CheckboxControlled.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
};

CheckboxControlled.defaultProps = {
  defaultValue: false,
};

export default CheckboxControlled;
